<template>
  <div>
    <template>
      <v-alert
        v-if="showAlert"
        dense
        outlined
        :type="typeAlert"
        transition="scroll-y-transition"
        border="left"
        elevation="2"
      >
        {{alertText}}
      </v-alert>
      <v-toolbar flat color="white">
        <v-col style="margin-top: auto">
          <v-combobox
            dense
            outlined
            v-model ="ministerio"
            :items ="catalogos.ministerios"
            label="Ministerio"
            item-text="nom_ministerio"
            item-value="id"
            @change="changeMinisterio()"
          >
          </v-combobox>
        </v-col>
        <v-spacer></v-spacer>
        <div class="text-center">
          <v-snackbar
            :timeout="5000"
            v-model="showTotal"
            absolute
            bottom
            color="success"
            text
              
          >
            Hay un Total de <strong>{{totalMiembros}}</strong> Miembros del Ministerio Seleccionado.
          </v-snackbar>
        </div>
        <v-spacer></v-spacer>
        <v-col style="text-align:end;">
          <v-container>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" 
                  fab
                  v-bind="attrs"
                  v-on="on"
                  @click="generaReporte"
                  :disabled="disabledButtonPdf"
                >
                  <v-icon>mdi-file</v-icon>
                </v-btn>
              </template>
              <span>Generar Reporte</span>
            </v-tooltip>
          </v-container>
        </v-col>
      </v-toolbar>
    </template>
    <div ref="pdf">
      <v-data-table v-show="showTable"
        :headers="headers"
        :items="miembros"
        :loading="loadTable"
        loading-text="Cargando... Por Favor Espera"
        hide-default-footer
        class="elevation-1"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        no-data-text="No hay Miembros para mostrar en el Ministerio seleccionado."
      >
      </v-data-table>
    </div>
    <div class="text-center pt-2" v-show="showTable">
      <v-pagination
        v-model="page"
        :length="pageCount"
        circle
      ></v-pagination>
    </div>
    <img v-show="false" src="../../../storage/img/Membrete.jpg" ref="idImg">
  </div>
</template>

<script>
import axios from 'axios'
import jsPDF from 'jspdf' 
import 'jspdf-autotable'

  export default {
    data: () => ({
      catalogos: {
        ministerios: []
      },
      ministerio: "",
      showTable: false,
      showTotal: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        { text: 'Nombre', align: 'start', value: 'nom_miembro' },
        { text: 'Apellidos', align: 'start', value: 'nom_apellidos' },
        { text: 'Ministerio', align: 'start', value: 'nom_ministerio' }
      ],
      miembros: [],
      loadTable: false,
      showAlert: false,
      typeAlert: '',
      alertText: '',
      url: process.env.VUE_APP_REPORTES_API,
      urlCatalogos: process.env.VUE_APP_CATALOGOS_API,
      imagen: ''
    }),

    computed: {
      disabledButtonPdf() {
        if(this.miembros.length > 0){
          return false
        }else{
          return true
        }
      },
      totalMiembros(){
        return this.miembros.length
      }
    },

    created () {
      var self = this

      self.loadMinisterios()

      this.$root.$on("generaPdfModal",function () {
        //self.date = []
        console.log("nico")
      })
    },

    watch: {
      showAlert () {
        var self = this
        if(self.showAlert){
          setTimeout(function(){
            self.showAlert = false;
          }, 3000);
        }
      }
    },

    methods: {
      changeMinisterio (){
        var self = this
        
        self.showTable = true
        self.loadData()
      },

      loadMinisterios (){
        var self = this

        axios.get(self.urlCatalogos+'ministerios', {
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
        .then(function (response) {
          self.catalogos.ministerios = response.data.data
        })
        .catch(function (error) {
          alert(error);
        });
      },

      loadData (){
        var self = this
        var Params = {
          id_ministerio: self.ministerio.id
        }

        self.loadTable = true
        
        axios.post(self.url+'ministerio', Params, {
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`
          }
        })
        .then(function (response) {
          self.miembros = response.data.data
          self.loadTable = false
          self.showTotal = true
        })
        .catch(function (error) {
          self.viewAlert("error", error);
        });
      },

      generaReporte() {
        var self = this
        var columns = [
          {header: "", dataKey: "count"},
          {header: "Nombre", dataKey: "nom_miembro"},
          {header: "Apellidos", dataKey: "nom_apellidos"},
          {header: "Ministerio", dataKey: "nom_ministerio"}
        ];
        var doc = new jsPDF('p', 'pt');
        var pageSize = doc.internal.pageSize;
        var repeat = true
        var cont = 0
        var i = 0
        var miembrosAux = []

        self.imagen = self.getBase64Image();
        

        self.miembros.forEach(miembro => {
          i++
          miembro.count = i
        })

        while (repeat) {
          miembrosAux = self.miembros.slice(cont,cont+15)
          
          doc.addImage(self.imagen, 'JPG', -4, 0, pageSize.width+15, pageSize.height+2) 
        
          doc.setFont("Helvetica")

          if(self.ministerio.nom_ministerio.length < 12){
            doc.setFontSize(24)
          }else{
            doc.setFontSize(18)
          }

          var fontSize = doc.internal.getFontSize();
          var txtWidth = doc.getStringUnitWidth('Reporte del Ministerio de ' + self.ministerio.nom_ministerio) * fontSize / doc.internal.scaleFactor;
          var x = (595 - txtWidth) / 2;

          doc.setTextColor('green')
          doc.text('Reporte del Ministerio de ' + self.ministerio.nom_ministerio, x, 180)

          doc.autoTable({
            body: miembrosAux,
            columns: columns,
            startY: 215,
            startX: 50,
            theme: 'striped',
            headStyles: { fillColor: 'green'},
            styles: { halign: 'center'},
            margin: { bottom: 250},
          })

          if(self.miembros.length <= cont+15){
            repeat = false
          }else{
            cont = cont+15
            doc.addPage()
          }
          
        }
        
        doc.save("Reporte del Ministerio de " + self.ministerio.nom_ministerio + ".pdf");
      },

      getBase64Image() {
        var img = new Image();
        img = this.$refs.idImg
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/jpg");
        return dataURL;
      },

      viewAlert (type, text){
        this.typeAlert = type
        this.alertText = text
        this.showAlert = true
      }
    }
  }
</script>