<template>
  <div>
    <template>
      <v-container class="grey lighten-3" style="margin-top: 10px;">
        <v-virtual-scroll
          :items="mensajes"
          height="550"
          item-height="100"
        >
          <template v-slot:default="{ item }">
            <v-row :justify="item.id_usuario == idUsuario ? 'end' : 'start'">
              <v-col md="auto">
                <v-card
                  class="pa-2"
                  outlined
                  shaped
                  elevation="10"
                  @click="seleccionar(item)"
                >
                  <v-list-item >
                    <v-list-item-content>
                      <div class="mb-4" style="color: blue;" v-if="item.id_usuario == idUsuario ? false : true">
                        {{item.usuario}}:
                      </div>
                      <v-list-item-title>{{item.mensaje}}</v-list-item-title>
                    </v-list-item-content>
                    <v-checkbox
                      v-model="item.selected"
                      color="primary"
                      v-if="item.selected"
                    ></v-checkbox>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-virtual-scroll>
      </v-container>
      
      <v-container>
        <v-row no-gutters >
          <v-col cols="11">
            <v-text-field
              v-model="mensaje.mensaje"
              outlined
              label="Escribe tu Anuncio"
              type="text"
              @keyup.enter="sent"
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-btn
              color="primary"
              text
              x-large
              block
              plain
              v-if="mensaje.mensaje.length > 0"
              @click="sent"
            >
              Enviar
            </v-btn>
            <v-btn
              color="primary"
              text
              x-large
              block
              plain
              v-else
              @click="limpiar"
            >
              Limpiar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <v-dialog v-model="showConfirm" persistent max-width="360">
      <v-card>
        <v-card-title class="headline">
          {{alertMsj}}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="cancelar"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="eliminar"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import Echo from 'laravel-echo'

window.Pusher = require('pusher-js')

  export default {
    props: {
      idUsuario: {
        //type: Number,
        default: 0
      }
    },
    data: () => ({
      showAlert: false,
      typeAlert: '',
      alertText: '',
      mensaje: {
        mensaje: '',
        usuario: 0
      },
      mensajes: [],
      alertMsj: '',
      showConfirm: false,
      url: process.env.VUE_APP_NOTIFICACIONES_API
    }),

    mounted(){
      var self = this

      self.getAnuncios();

      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: '686e1e87df22ea2aa66c',
        wsHost: process.env.VUE_APP_WSHOST,
        encrypted: true,
        forceTLS: true,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
      })

      window.Echo.channel('anuncio').listen('.App\\Events\\NuevoAnuncio', (e) => {
        self.mensajes.push({mensaje: e.mensaje, id_usuario: e.usuario, selected: false, id: e.id, usuario: e.nombre})
      })

      window.Echo.channel('anuncio').listen('.App\\Events\\DeleteAnuncio', (e) => {
        var idTmp;
        
        if(e.id == 0){
          self.mensajes = []
        }else{
          idTmp = e.id.split(',');
          idTmp.forEach(id => {
            var i = self.mensajes.findIndex(mensaje => mensaje.id == id)
            self.mensajes.splice( i, 1 );
          })
        }
      })
    },

    watch: {
      showAlert () {
        var self = this
        if(self.showAlert){
          setTimeout(function(){
            self.showAlert = false;
          }, 3000);
        }
      }
    },

    methods: {
      sent() {
        var self = this

        if(self.mensaje.mensaje.length > 0){
          self.mensaje.usuario = self.idUsuario
          //self.messages.push(self.message)

          axios.post(self.url+'anuncios', self.mensaje, {
            headers: {
              'Authorization': `Bearer ${localStorage.sessionID}`,
              'Access-Control-Allow-Origin': '*'
            }
          })
          .then(function (response) {
            self.mensaje = {
              mensaje: '',
              usuario: 0          
            }
          })
          .catch(function (error) {
            self.viewAlert('error', error)
          });
        }
      },

      getAnuncios (){
        var self = this

        axios.get(self.url+'anuncios', {
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
        .then(function (response) {
          self.mensajes = response.data.data
        })
        .catch(function (error) {
          self.viewAlert('error', error);
        });
      },

      seleccionar(item){
        item.selected = !item.selected;
      },

      limpiar() {
        var self = this,
        total = true

        self.mensajes.forEach(mensaje => {
          if(mensaje.selected){
            total = false
          }
        })

        if(total){
          self.alertMsj = "¿Realmente deseas eliminar todos los Anuncios?"
        }else{
          self.alertMsj = "¿Realmente deseas eliminar los Anuncios seleccionados?"
        }

        self.showConfirm = true
      },

      cancelar (){
        this.showConfirm = false

        //this.getAnuncios()
      },

      eliminar (){
        var self = this
        var tmp = ''
        self.mensajes.forEach(mensaje => {
          if(mensaje.selected){
            tmp += mensaje.id+','
          }
        })
        
        if(tmp == ''){
          tmp = '0,'
        }

        var Params = {
          id: tmp
        }

        axios.delete(self.url+'anuncios', { data: Params,
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`
          }
        })
        .then(function (response) {
          self.cancelar()
        })
        .catch(function (error) {
          self.viewAlert('error', error)
        });
      },

      viewAlert (type, text){
        this.typeAlert = type
        this.alertText = text
        this.showAlert = true
      }
    }
  }
</script>
