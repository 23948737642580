var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[(_vm.showAlert)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":_vm.typeAlert,"transition":"scroll-y-transition","border":"left","elevation":"2"}},[_vm._v(" "+_vm._s(_vm.alertText)+" ")]):_vm._e(),_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-spacer'),_c('v-col',{staticStyle:{"text-align":"center"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar Ocupación","single-line":"","hide-details":""},model:{value:(_vm.searchOcupaciones),callback:function ($$v) {_vm.searchOcupaciones=$$v},expression:"searchOcupaciones"}})],1),_c('v-col',{staticStyle:{"text-align":"end"}},[_c('v-container',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","small":""},on:{"click":_vm.addOcupacion}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Agregar Ocupación")])])],1)],1)],1)],_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.ocupaciones,"search":_vm.searchOcupaciones,"loading":_vm.loadTable,"loading-text":"Cargando... Por Favor Espera","hide-default-footer":"","page":_vm.page,"items-per-page":_vm.itemsPerPage,"no-data-text":"No hay Ocupaciones para mostrar."},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"cyan","dark":"","x-small":"","fab":""},on:{"click":function($event){return _vm.editOcupacion(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","dark":"","x-small":"","fab":""},on:{"click":function($event){return _vm.deleteOcupacion(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}])}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('add-catalogo'),_c('remove-confirm')],2)}
var staticRenderFns = []

export { render, staticRenderFns }