<template>
  <div>
    <template>
      <v-alert
        v-if="showAlert"
        dense
        outlined
        :type="typeAlert"
        transition="scroll-y-transition"
        border="left"
        elevation="2"
      >
        {{alertText}}
      </v-alert>
      <v-toolbar flat color="white">
        <v-col style="margin-top: auto">
          <v-combobox
            dense
            outlined
            v-model ="reporte"
            :items ="catalogos.tipos"
            label="Tipo Reporte"
            item-text="nom_reporte"
            item-value="id"
            @change="changeReporte()"
          >
          </v-combobox>
        </v-col>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-col style="text-align:end;">
          <v-container>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" 
                  fab
                  v-bind="attrs"
                  v-on="on"
                  @click="generaReporte"
                  :disabled="disabledButtonPdf"
                >
                  <v-icon>mdi-file-pdf-box</v-icon>
                </v-btn>
              </template>
              <span>Generar Reporte</span>
            </v-tooltip>
          </v-container>
        </v-col>
      </v-toolbar>
    </template>
    <div ref="pdf">
      <v-data-table v-show="showTable"
        :headers="headers"
        :items="totales"
        :loading="loadTable"
        loading-text="Cargando... Por Favor Espera"
        hide-default-footer
        class="elevation-1"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        no-data-text="No hay Datos para mostrar."
      >
      </v-data-table>
    </div>
    <div class="text-center pt-2" v-show="showTable">
      <v-pagination
        v-model="page"
        :length="pageCount"
        circle
      ></v-pagination>
    </div>
    <img v-show="false" src="../../../storage/img/Membrete.jpg" ref="idImg">
  </div>
</template>

<script>
import axios from 'axios'
import jsPDF from 'jspdf' 
import 'jspdf-autotable'

  export default {
    data: () => ({
      catalogos: {
        tipos: [
          {
            id: 1,
            nom_reporte: "Totales del Pre-Registro"
          },
          {
            id: 2,
            nom_reporte: "Totales de la Cooperación"
          }
        ]
      },
      reporte: "",
      showTable: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [],
      totales: [],
      loadTable: false,
      showAlert: false,
      typeAlert: '',
      alertText: '',
      url: process.env.VUE_APP_REGISTRO_API,
      imagen: ''
    }),

    computed: {
      disabledButtonPdf() {
        if(this.totales.length > 0){
          return false
        }else{
          return true
        }
      },
      totalMiembros(){
        return this.totales.length
      }
    },

    created () {
      var self = this

      this.$root.$on("generaPdfModal",function () {
        //self.date = []
      })
    },

    watch: {
      showAlert () {
        var self = this
        if(self.showAlert){
          setTimeout(function(){
            self.showAlert = false;
          }, 3000);
        }
      }
    },

    methods: {
      changeReporte (){
        var self = this
        
        self.loadData()
      },

      loadTotalRegistro (){
        var self = this

        self.headers = [
          { text: 'Casa de Dios', align: 'start', value: 'nom_congregacion' },
          { text: 'Total Registrados', align: 'start', value: 'num_total' }
        ]

        self.showTable = true
        self.loadTable = true

        axios.get(self.url+'varones/totalPreRegistro', {
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
        .then(function (response) {
          var totales = 0

          self.totales = response.data.data
          
          self.totales.forEach(total => {
            totales += total.num_total
          })
          self.totales.push({nom_congregacion: "Totales", num_total:totales})
          self.loadTable = false
        })
        .catch(function (error) {
          alert(error);
        });
      },

      loadData(){
        var self = this
        self.totales = []
        if(self.reporte.id == 1){
          self.loadTotalRegistro()
        }else if(self.reporte.id == 2){
          self.loadTotalCooperacion()
        }
      },

      loadTotalCooperacion(){
        var self = this
        self.loadTable = true

        self.headers = [
          { text: 'Casa de Dios', align: 'start', value: 'nom_congregacion' },
          { text: 'Total Cooperación', align: 'start', value: 'num_totalCooperacion' }
        ]

        self.showTable = true

        axios.get(self.url+'varones/totalCooperacion', {
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
        .then(function (response) {
          var totalCooperacion = 0

          self.totales = response.data.data
          
          self.totales.forEach(total => {
            totalCooperacion += total.num_totalCooperacion
          })
          self.totales.push({nom_congregacion: "Totales", num_totalCooperacion: totalCooperacion})
          self.loadTable = false
        })
        .catch(function (error) {
          alert(error);
        });
      },

      generaReporte() {
        var self = this
        var tipoReporte = self.reporte.id
        var columns = []
        if(tipoReporte == 1){
          columns = [
            {header: "Casa de Dios", dataKey: "nom_congregacion"},
            {header: "Total Registrados", dataKey: "num_total"}
          ];
        }else{
          columns = [
            {header: "Casa de Dios", dataKey: "nom_congregacion"},
            {header: "Cooperación Total", dataKey: "num_totalCooperacion"},
          ]
        }
        var doc = new jsPDF('p', 'pt');
        var pageSize = doc.internal.pageSize;
        var repeat = true
        var cont = 0
        var i = 0
        var totalesAux = []

        self.imagen = self.getBase64Image();
        

        self.totales.forEach(total => {
          i++
          total.count = i
        })

        while (repeat) {
          totalesAux = self.totales.slice(cont,cont+15)
          
          doc.addImage(self.imagen, 'JPG', -4, 0, pageSize.width+15, pageSize.height+2) 
        
          doc.setFont("Helvetica")

          /*if(self.totales.nom_congregacion.length < 12){
            doc.setFontSize(24)
          }else{
            doc.setFontSize(18)
          }*/

          var fontSize = doc.internal.getFontSize();
          var txtWidth;
          if(tipoReporte == 1){
            txtWidth = doc.getStringUnitWidth('Reporte de Pre-Registro Congreso de Varones') * fontSize / doc.internal.scaleFactor;
          }else{
            txtWidth = doc.getStringUnitWidth('Reporte de Cooperación Congreso de Varones') * fontSize / doc.internal.scaleFactor;
          }
          var x = (595 - txtWidth) / 2;

          doc.setTextColor('green')
          if(tipoReporte == 1){ 
            doc.text('Reporte de Pre-Registro Congreso de Varones', x, 180)
          }else{
            doc.text('Reporte de Cooperación Congreso de Varones', x, 180)
          }

          doc.autoTable({
            body: totalesAux,
            columns: columns,
            startY: 215,
            startX: 50,
            theme: 'striped',
            headStyles: { fillColor: 'green'},
            styles: { halign: 'center'},
            margin: { bottom: 250},
            columnStyles: {0:{halign: 'left'}}
          })

          if(self.totales.length <= cont+15){
            repeat = false
          }else{
            cont = cont+15
            doc.addPage()
          }
          
        }
        
        if(tipoReporte == 1){
          doc.save("Reporte de Pre-Registro Congreso de Varones.pdf");
        }else{
          doc.save("Reporte de Cooperación Congreso de Varones.pdf");
        }
      },

      getBase64Image() {
        var img = new Image();
        img = this.$refs.idImg
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/jpg");
        return dataURL;
      },

      viewAlert (type, text){
        this.typeAlert = type
        this.alertText = text
        this.showAlert = true
      }
    }
  }
</script>