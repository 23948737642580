<template>
  <div>
    <template>
      <v-alert
        v-if="showAlert"
        dense
        outlined
        :type="typeAlert"
        transition="scroll-y-transition"
        border="left"
        elevation="2"
      >
        {{alertText}}
      </v-alert>
      <v-toolbar flat color="white">
        <v-spacer></v-spacer>
        <v-col style="text-align:center;">
        <v-text-field
          v-model="searchUsuarios"
          append-icon="mdi-magnify"
          label="Buscar Usuarios"
          single-line
          hide-details
        ></v-text-field>
        </v-col>
        <v-col style="text-align:end;">
        <v-container>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" 
                fab 
                small
                v-bind="attrs"
                v-on="on"
                @click="addUsuario"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Agregar Usuario</span>
          </v-tooltip>
        </v-container>
        </v-col>
      </v-toolbar>
    </template>
    <v-data-table
      :headers="headers"
      :items="usuarios"
      :search="searchUsuarios"
      :loading="loadTable"
      loading-text="Cargando... Por Favor Espera"
      hide-default-footer
      class="elevation-1"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      no-data-text="No hay Usuarios para mostrar."
    >
    <template v-slot:item.actions="{item}">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2"
            color="cyan"
            dark
            x-small
            fab
            v-bind="attrs"
            v-on="on"
            @click="editUsuario(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Editar</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="error"
            dark
            x-small
            fab
            v-bind="attrs"
            v-on="on"><!--
            @click="deleteUsuario(item)"
          -->
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>Eliminar</span>
      </v-tooltip>
    </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        circle
      ></v-pagination>
    </div>
    <add-usuario></add-usuario>
    <!--remove-confirm></remove-confirm-->
  </div>
</template>

<script>
import addUsuario from './AddUsuario.vue'
//import removeConfirm from './RemoveConfirm.vue'
import axios from 'axios'

  export default {
    props: {
      idUsuario: {
        //type: Number,
        default: 0
      }
    },
    data: () => ({
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        {
          text: 'Nombre',
          align: 'start',
          value: 'nombre',
        },
        {
          text: 'Tipo',
          align: 'start',
          value: 'nom_rol',
        },
        { text: '', value: 'actions', align: 'end', sortable: false },
      ],
      usuarios: [],
      searchUsuarios: '',
      showAlert: false,
      typeAlert: '',
      alertText: '',
      loadTable: false,
      url: process.env.VUE_APP_MIEMBROS_API
    }),

    created () {
      var self = this
      this.getUsuarios()

      /*this.$root.$on("addModalCatalogCongregacionesClosed",function (Params) {
        if(!Params.success){
          self.viewAlert("error", Params.message)
        }
        
        self.getCongregaciones()
      })*/
    },

    components:{
      "add-usuario": addUsuario,
      //"remove-confirm": removeConfirm
    },

    watch: {
      showAlert () {
        var self = this
        if(self.showAlert){
          setTimeout(function(){
            self.showAlert = false;
          }, 3000);
        }
      }
    },

    methods: {
      addUsuario (){
        var Params = {
          show: true, 
          isEdit: false,
          usuario: {}
        }
        
        this.$root.$emit("addUsuarioModal", Params)
      },
      
      editUsuario (item){
        var Params = {
          show: true, 
          isEdit: true,
          usuario: item
        }
        
        this.$root.$emit("addUsuarioModal", Params)
      },
      /*
      deleteUsuario (item){
        var Params = {
          show: true, 
          type: "congregación",
          id: item.id
        }
        
        this.$root.$emit("removeConfirmModal", Params)
      },*/

      getUsuarios (){
        var self = this

        self.loadTable = true

        axios.get(self.url+'usuarios/'+self.idUsuario, {
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
        .then(function (response) {
          self.usuarios = response.data.data
          self.loadTable = false
        })
        .catch(function (error) {
          alert(error);
        });
      },

      viewAlert (type, text){
        this.typeAlert = type
        this.alertText = text
        this.showAlert = true
      }
    }
  }
</script>
