<template>
  <v-dialog v-model="show" persistent max-width="500">
    <v-skeleton-loader
      v-if="loading"
      type="list-item-three-line, list-item-three-line, actions"
      :loading="loading"
    ></v-skeleton-loader>
    <v-card
      v-if="!loading"
      :loading="loading"
      shaped
    >
      <v-card-title>
        <span class="headline">Cooperación</span>
      </v-card-title>

      <v-card-text>

        <v-data-table
          :headers="headers"
          :items="cooperaciones"
          :loading="loading"
          loading-text="Cargando... Por Favor Espera"
          hide-default-footer
          class="elevation-1"
          fixed-header
          no-data-text="No hay Cooperaciones Registradas aún."
        >
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="close">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import axios from 'axios'


export default {
  name: 'cooperacion',

  data: () => ({
    cooperaciones: [],
    show: false,
    idMiembro: 0,
    loading: false,
    headers: [
      { text: 'Fecha Movimiento', align: 'start', value: 'fecha_movto' },
      { text: 'Tipo Movimiento', align: 'start', value: 'des_tipoMovto'},
      { text: 'Cantidad', align: 'start', value: 'cantidad' }
    ],
    urlApi: process.env.VUE_APP_REGISTRO_API,
  }),

  created(){
    var self = this

    this.$root.$on("cooperacionModal",function (Parameters) {
      self.show = Parameters.show
      self.idMiembro = Parameters.id

      self.loadCooperaciones()
    })
  },

  methods: {
    close () {
      this.show = false
      this.cooperaciones = []
      this.idMiembro = 0
      this.loading = false
    },

    loadCooperaciones() {
      var self = this

      self.loading = true

      axios.get(self.urlApi + 'cooperacion/' + self.idMiembro, {
        headers: {
          'Authorization': `Bearer ${localStorage.sessionID}`
        }
      }).then(function (response) {
        if(response.data.success){
          self.cooperaciones = response.data.data
          self.loading = false
        }
      });
    }
  }
};
</script>