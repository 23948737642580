<template>
  <v-dialog v-model="show" persistent max-width="350">
    <v-card>
      <v-card-title class="headline">
        ¿Esta seguro de que desea eliminar?
      </v-card-title>
      <v-card-text>
        Al eliminar un(a) {{tittleType}}, usted acepta retirar dicho(a) {{tittleType}} de todos los miembros que lo(a) tengan asignado. 
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green darken-1"
          text
          @click="decline"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="green darken-1"
          text
          @click="accept"
        >
          Eliminar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import axios from 'axios'

export default {
  name: 'RemoveConfirm',

  data: () => ({
    show: false,
    tittleType: '',
    id: 0,
    url: process.env.VUE_APP_CATALOGOS_API
  }),

  created(){
    var self = this

    this.$root.$on("removeConfirmModal",function (Parameters) {
      self.show = Parameters.show
      self.tittleType = Parameters.type
      self.id = Parameters.id
    })
  },

  methods: {

    decline () {
      this.show = false
    },

    accept () {
      var self = this
      
      if(self.tittleType == "Ministerio y/o Comisión"){
        axios.delete(self.url+'ministerios/'+self.id, {
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
        .then(function (response) {
          self.$root.$emit("addModalCatalogMinisteriosClosed", response.data)
        })
        .catch(function (error) {
          var Parameters = {
            success: false,
            message: "El Ministerio no pudo ser eliminado, contacte a su Administrador.  Error: ".error,
          }
          self.$root.$emit("addModalCatalogMinisteriosClosed", Parameters)
        });
    
      }else if(self.tittleType == 'ocupación'){
        axios.delete(self.url+'ocupaciones/'+self.id, {
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
        .then(function (response) {
          self.$root.$emit("addModalCatalogOcupacionesClosed", response.data)
        })
        .catch(function (error) {
          var Parameters = {
            success: false,
            message: "La Ocupación no pudo ser eliminada, contacte a su Administrador.  Error: ".error,
          }
          self.$root.$emit("addModalCatalogOcupacionesClosed", Parameters)
        });
      }else if(self.tittleType == 'congregación'){
        axios.delete(self.url+'congregaciones/'+self.id, {
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
        .then(function (response) {
          self.$root.$emit("addModalCatalogCongregacionesClosed", response.data)
        })
        .catch(function (error) {
          var Parameters = {
            success: false,
            message: "La Congregación no pudo ser eliminada, contacte a su Administrador.  Error: ".error,
          }
          self.$root.$emit("addModalCatalogCongregacionesClosed", Parameters)
        });
      }
      self.decline()
    }
  }
};
</script>