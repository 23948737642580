<template>
  <div>
    <template>
      <v-alert
        v-if="showAlert"
        dense
        outlined
        :type="typeAlert"
        transition="scroll-y-transition"
        border="left"
        elevation="2"
      >
        {{alertText}}
      </v-alert>
      <v-toolbar flat color="white">
        <v-col style="margin-top: auto">
          <v-combobox
            dense
            outlined
            v-model ="tipoListado"
            :items ="catalogos.listados"
            label="Tipo Listado"
            item-text="nom_tipo"
            item-value="id"
            @change="loadCatalogos()"
          >
          </v-combobox>
        </v-col>
        <v-col style="margin-top: auto">
          <v-combobox
            dense
            outlined
            v-model ="congregacion"
            :items ="catalogos.congregaciones"
            label="Casa de Dios"
            item-text="nom_congregacion"
            item-value="id"
            @change="getMiembrosPreRegistro()"
            :disabled="disabledCongregaciones"
          >
          </v-combobox>
        </v-col>
         <div>
          <v-snackbar
            :timeout="5000"
            v-model="showTotal"
            absolute
            bottom
            color="success"
            text
          >
            Hay un Total de <strong>{{totalMiembros}}</strong> Joven(es) Registrados para está Congregación.
          </v-snackbar>
        </div>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-col style="text-align:end;">
          <v-container>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" 
                  fab
                  v-bind="attrs"
                  v-on="on"
                  @click="generaListados"
                  :disabled="disabledButtonPdf"
                >
                  <v-icon>mdi-file-pdf-box</v-icon>
                </v-btn>
              </template>
              <span>Generar Listados</span>
            </v-tooltip>
          </v-container>
        </v-col>
      </v-toolbar>
    </template>
    <div class="text--center" v-if="loading">
      <v-row
        class="fill-height ma-0"
        align="center"
        justify="center"
      >
        <v-col style="margin-top: 50px;"
          align="center"
          justify="center"
        >
          <v-progress-circular
            :size="50"
            :width="5"
            color="blue"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-data-table v-show="showTable"
        :headers="tipoListado.id == 1 ? headersSencillo : headersDetalle"
        :items="miembros"
        :loading="loadTable"
        loading-text="Cargando... Por Favor Espera"
        hide-default-footer
        class="elevation-1"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        no-data-text="No hay Jóvenes Registrados en está Congregación."
      >
      </v-data-table>
    </div>
    <div class="text-center pt-2" v-show="showTable">
      <v-pagination
        v-model="page"
        :length="pageCount"
        circle
      ></v-pagination>
    </div>
    <!--img v-show="false" src="../../../storage/img/Membrete.jpg" ref="idImg"-->
  </div>
</template>

<script>
import axios from 'axios'
import jsPDF from 'jspdf' 
import 'jspdf-autotable'

  export default {
    name: 'ListadosJovenes',

    data: () => ({
      showAlert: false,
      typeAlert: '',
      alertText: '',
      miembros: [],
      catalogos: {
        congregaciones: [],
        listados: [
          {"id": 1, "nom_tipo": "Sencillo"},
          {"id": 2, "nom_tipo": "Detallado"}
        ]
      },
      showTable: false,
      loadTable: false,
      loading: false,
      showTotal: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headersDetalle: [
        { text: 'Nombre', align: 'start', value: 'nom_miembro' },
        { text: 'Apellidos', align: 'start', value: 'nom_apellido'},
        { text: 'Edad', align: 'start', value: 'edad' },
        { text: 'Casa de Dios', align: 'start', value: 'nom_congregacion' },
        { text: 'Observaciones', align: 'start', value: 'observaciones' }
      ],
      headersSencillo: [
        { text: 'Nombre', align: 'start', value: 'nom_miembro' },
        { text: 'Apellidos', align: 'start', value: 'nom_apellido'},
        { text: 'Casa de Dios', align: 'start', value: 'nom_congregacion' }        
      ],
      congregacion: '',
      tipoListado: '',
      url: process.env.VUE_APP_REGISTRO_API,
    }),

    computed: {
      disabledButtonPdf() {
        if(this.miembros.length > 0){
          return false
        }else{
          return true
        }
      },

      disabledCongregaciones() {
        if(this.tipoListado && this.tipoListado.id != 0){
          return false
        }else{
          return true
        }
      },

      totalMiembros(){
        return this.miembros.length
      }
    },

    watch: {
      showAlert () {
        var self = this
        if(self.showAlert){
          setTimeout(function(){
            self.showAlert = false;
          }, 3000);
        }
      }
    },

    methods: {
      
      loadCatalogos(){
        this.showTable = false
        this.loading = true
        this.loadCongregaciones();
      },

      loadCongregaciones(){
        var self = this

        axios.get(self.url+'congregaciones')
        .then(function (response) {
          self.catalogos.congregaciones = response.data.data
          self.catalogos.congregaciones.unshift({id: 0, nom_congregacion: "Todas"})
          self.congregacion = {id: 0, nom_congregacion: "Todas"}
          self.getMiembrosPreRegistro()
        });
      },

      getMiembrosPreRegistro (){
        var self = this
        self.loading = false
        self.loadTable = true
        self.showTable = true
        
        axios.get(self.url + 'preRegistro/' + self.congregacion.id, {
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
        .then(function (response) {
          self.miembros = response.data.data
          self.loadTable = false
          //self.loading = false
          self.showTotal = true
        })
        .catch(function (error) {
          self.viewAlert('error', error);
        });
      },

      generaListados() {
        var self = this
        var columns = []
        
        if(self.tipoListado.id == 1){
          columns = [
            {header: "", dataKey: "count"},
            {header: "Nombre(s)", dataKey: "nom_miembro"},
            {header: "Apellidos", dataKey: "nom_apellido"},
            {header: "Casa de Dios", dataKey: "nom_congregacion"},
          ]
        }else{
          columns = [
            {header: "", dataKey: "count"},
            {header: "Nombre(s)", dataKey: "nom_miembro"},
            {header: "Apellidos", dataKey: "nom_apellido"},
            {header: "Edad", dataKey: "edad"},
            {header: "Casa de Dios", dataKey: "nom_congregacion"},
            {header: "Observaciones", dataKey: "observaciones"},
          ]
        }
        
        var doc = new jsPDF('p', 'pt');
        var i = 0
        var titulo
        if(self.congregacion.id == 0){
          titulo = 'Listado de todas las Congregaciones'
        }else{
          titulo = 'Listado de Casa de Dios ' + self.congregacion.nom_congregacion
        }

        self.miembros.forEach(miembro => {
          i++
          miembro.count = i
        })

        doc.setFont("Helvetica")

        var fontSize = doc.internal.getFontSize();
        var txtWidth = doc.getStringUnitWidth(titulo) * fontSize / doc.internal.scaleFactor;
        var x = (595 - txtWidth) / 2;

        doc.setTextColor('green')
        
        doc.text(titulo, x, 30)
        
        doc.autoTable({
          body: self.miembros,
          columns: columns,
          startY: 80,
          theme: 'striped',
          headStyles: { fillColor: 'green'},
          styles: { halign: 'center'},
          margin: { bottom: 50},
          columnStyles: {0:{halign: 'left'}}
        })
          
        doc.save(titulo + ".pdf");
        
      },

      viewAlert (type, text){
        this.typeAlert = type
        this.alertText = text
        this.showAlert = true
      }
    }
  }
</script>