<template>
  <v-dialog v-model="show" persistent max-width="1200px">
    <v-card>
      <v-card-title>
        <span class="headline">Buscar Código Postal y/o Colonia</span>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-autocomplete
                  v-model="idPais"
                  :items="catalogos.paises"
                  item-text="nom_pais"
                  item-value="id"
                  label="País"
                  outlined
                  required
                  disabled
                  @change="changePais"
                ></v-autocomplete>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-autocomplete
                  v-model="idEstado"
                  :items="catalogos.estados"
                  item-text="nom_estado"
                  item-value="id"
                  label="Estado"
                  outlined
                  required
                  :disabled="isLoading"
                  @change="changeEstado"
                ></v-autocomplete>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-autocomplete
                  v-model="idMunicipio"
                  :items="catalogos.municipios"
                  item-text="nom_municipio"
                  item-value="id"
                  label="Municipio"
                  outlined
                  required
                  :disabled="isLoading"
                  @change="changeMunicipio"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-autocomplete
                  v-model="idColonia"
                  :items="catalogos.colonias"
                  outlined
                  item-text="nom_colonia"
                  item-value="id"
                  label="Colonia"
                  :disabled="isLoading"
                  @change="changeColonia"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="4"
                align="center"
                justify="center"
              >
                <v-label v-if="showCP">
                  Su código Postal es: {{numCP}}
                </v-label>
                <v-progress-circular v-show="isLoading" indeterminate :size="50" color="grey"></v-progress-circular>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="showCP" color="blue darken-1" text @click="close">Aceptar</v-btn>
        <v-btn v-else color="blue darken-1" text @click="close">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import axios from 'axios'

export default {
  name: 'SearchCP',
  data: () => ({
    show: false,
    showCP: false,
    firsLoad: false,
    idPais: 0,
    idEstado: 0,
    idMunicipio: 0,
    numCP: 0,
    idColonia: 0,
    items: [],
    catalogos:{
      paises: [],
      estados: [],
      municipios: [],
      colonias: []
    },
    url: process.env.VUE_APP_LOCALIZACIONES_API,
    isLoading: false
  }),

  created(){
    var self = this

    this.$root.$on("cpModal",function (Parameters) {
      self.firsLoad = true
      self.isLoading = true
      self.catalogos.paises = []
      self.catalogos.estados = []
      self.catalogos.municipios = []
      self.catalogos.colonias = []
      self.show = Parameters.show
      self.idPais = 1
      self.idEstado = 0
      self.idMunicipio = 0
      self.numCP = 0
      self.idColonia = 0
      self.showCP = false
      self.loadCatalogos()
    })
  },

  methods: {
    close () {
      var self = this
      
      self.show = false
      
      if(self.showCP){
        var Params = {
          idPais: self.idPais,
          idEstado: self.idEstado,
          idMunicipio: self.idMunicipio,
          numCP: self.numCP,
          idColonia: self.idColonia,
          catalogos: self.catalogos
        }

        self.$root.$emit("cpModalClose", Params)
      }
    },

    loadCatalogos(){
      this.loadPaises();
      if(this.idPais && this.firsLoad){
        this.loadEstados(this.idPais)
      }
      if(this.idEstado && this.firsLoad){
        this.loadMunicipios(this.idEstado)
      }
      if(this.idMunicipio && this.firsLoad){
        this.loadColonias(this.idMunicipio)
      }
      this.firsLoad = false
    },

    loadPaises(){
      var self = this
      self.isLoading = true

      axios.get(self.url+'paises', {
        headers: {
          'Authorization': `Bearer ${localStorage.sessionID}`,
          'Access-Control-Allow-Origin': '*'
        }, 
      })
      .then(function (response) {
        self.catalogos.paises = response.data.data
        //self.isLoading = false
      })
      .catch(function (error) {
        self.isLoading = false
        alert(error);
      });
    },
    
    changePais(){
      var self = this

      if(!self.firsLoad){
        self.catalogos.estados = []
        self.catalogos.municipios = []
        self.idEstado = null
        self.idMunicipio = null
        self.numCP = null
        self.showCP = false

        if(self.idPais && self.idPais != 0){
          self.loadEstados(self.idPais);
        }
      }
    },

    loadEstados(idPais){
      var self = this
      var Params = {
        id_pais: idPais
      }
      self.isLoading = true

      axios.post(self.url+'estados', Params, {
        headers: {
          'Authorization': `Bearer ${localStorage.sessionID}`,
          'Access-Control-Allow-Origin': '*'
        }, 
      })
      .then(function (response) {
        self.catalogos.estados = response.data.data
        self.isLoading = false
      })
      .catch(function (error) {
        self.isLoading = false
        alert(error);
      });
    },
    
    changeEstado(){
      var self = this

      if(!self.firstLoad){
        self.catalogos.municipios = []
        self.idMunicipio = null
        self.numCP = null
        self.showCP = false
      }
      if(self.idEstado && self.idEstado != 0){
        self.loadMunicipios(self.idEstado);
      }

    },

    loadMunicipios(idEstado){
      var self = this
      var Params = {
        id_estado: idEstado
      }
      self.isLoading = true

      axios.post(self.url+'municipios', Params, {
        headers: {
          'Authorization': `Bearer ${localStorage.sessionID}`,
          'Access-Control-Allow-Origin': '*'
        }, 
      })
      .then(function (response) {
        self.catalogos.municipios = response.data.data
        self.isLoading = false
      })
      .catch(function (error) {
        self.isLoading = false
        alert(error);
      });
    },
    
    changeMunicipio(){
      var self = this

      if(!self.firstLoad){
        self.catalogos.colonias = []
        self.idColonia = null
        self.numCP = null
        self.showCP = false

        if(self.idMunicipio && self.idMunicipio != 0){
          self.loadColonias(self.idMunicipio);
        }
      }
      
    },

    loadColonias(idMunicipio){
      var self = this
      var Params = {
        id_municipio: idMunicipio
      }
      self.isLoading = true

      axios.post(self.url+'colonias', Params, {
        headers: {
          'Authorization': `Bearer ${localStorage.sessionID}`,
          'Access-Control-Allow-Origin': '*'
        }, 
      })
      .then(function (response) {
        self.catalogos.colonias = response.data.data
        self.isLoading = false
      })
      .catch(function (error) {
        self.isLoading = false
        alert(error);
      });
      
    },

    changeColonia(){
      var self = this

      self.catalogos.colonias.forEach(colonia => {
        if(colonia.id == self.idColonia){
          self.numCP = colonia.codigo_postal
          self.showCP = true
        }
      })
    }

  }
};
</script>