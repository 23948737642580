<template>
  <v-dialog v-model="show" persistent max-width="1000px">
    <v-card>
      <v-card-title>
        <span class="headline">Capturar Imagen</span>
      </v-card-title>
      <v-card-text>
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <h2 align="center">Cámara Actual</h2>
              <!--div class="border"-->
                <vue-web-cam
                  ref="webcam"
                  :device-id="deviceId"
                  width="100%"
                  height="300"
                  @started="onStarted"
                  @stopped="onStopped"
                  @cameras="onCameras"
                  @camera-change="onCameraChange"
                />
                  <!--@error="onError"
                />
              </div-->

              <div class="row">
                <div class="col-md-12">
                  <v-select 
                    outlined 
                    v-model="camera"
                    :items="devices"
                    label="Seleccionar Cámara"
                    item-text="label"
                    item-value="deviceId"
                  >
                  </v-select>
                </div>
                <!--div class="col-md-12">
                  <v-btn 
                    class="mx-2" 
                    small 
                    outlined 
                    color="primary" 
                    fab 
                    @click="onCapture"
                  >
                    <v-icon>mdi-camera</v-icon>
                  </v-btn>
                  <v-btn class="mx-2" small outlined rounded color="danger" @click="onStop">
                    Detener Cámara
                  </v-btn>
                  <v-btn class="mx-2" small outlined rounded color="success" @click="onStart">
                    Iniciar Cámara
                  </v-btn>
                </div-->
              </div>
            </div>
            <div class="col-md-6">
              <h2 align="center">Imágen Capturada</h2>
              <figure align="center" class="figure">
                <img :src="img" class="img-responsive" />
              </figure>
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onCapture"><v-icon>mdi-camera</v-icon></v-btn>
        <v-btn color="blue darken-1" text @click="save" v-if="isCapture">Aceptar</v-btn>
        <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import WebCam from './webcam.vue'
import axios from 'axios'

export default {
  name: 'WebCamModal',

  components: {
    "vue-web-cam": WebCam
  },

  data: () => ({
    show: false,
    img: null,
    camera: null,
    deviceId: null,
    devices: [],
    cameraActive: false,
    nombreImagen: null,
    isCapture: false,
    url: process.env.VUE_APP_MIEMBROS_API
  }),

  computed: {
    device: function() {
      return this.devices.find(n => n.deviceId === this.deviceId);
    }
  },

  watch: {
    camera: function(id) {
      this.deviceId = id;
    },
    devices: function() {
      // Once we have a list select the first one
      const [first] = this.devices;
      if (first) {
        this.camera = first.deviceId;
        this.deviceId = first.deviceId;
      }
      
    }
  },

  created(){
    var self = this

    this.$root.$on("webCamModal",function (Parameters) {
      self.show = Parameters.show
      if(self.deviceId){
        self.onStart()
      }
    })
  },

  methods: {
    onCapture() {
      this.img = this.$refs.webcam.capture();
      this.isCapture = true;
    },

    onStarted(stream) {
      this.cameraActive = stream.active
    },
    onStopped(stream) {
      this.cameraActive = stream.active
    },
    onStop() {
      this.$refs.webcam.stop();
    },
    onStart() {
      var self = this
      //if(self.$refs.webcam){
        self.$refs.webcam.start();
      //}
    },
    /*onError(error) {
      console.log("On Error Event", error);
    },*/
    onCameras(cameras) {
      this.devices = cameras;
      //console.log("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      //console.log("On Camera Change Event", deviceId);
    },

    close () {
      var self = this
      
      self.onStop()
      self.show = false
      self.img = null
      self.isCapture = false
    },

    save () {
      var self = this

      axios.post(self.url+'imgMiembro', {imagen: self.img}, {
        headers: {
          'Authorization': `Bearer ${localStorage.sessionID}`
        }, 
      })
      .then(function (response) {
        self.nombreImagen = response.data.data
        self.close()
        self.$root.$emit("webCamClosed", self.nombreImagen)
      })
      .catch(function (error) {
        alert(error);
      });
    }
  }
};
</script>