<template>
  <div>
    <template>
      <v-alert
        v-if="showAlert"
        dense
        outlined
        :type="typeAlert"
        transition="scroll-y-transition"
        border="left"
        elevation="2"
      >
        {{alertText}}
      </v-alert>
      <v-toolbar flat color="white">
        <div class="text-center">
          <v-menu
            v-model="showFiltro"
            :close-on-content-click="false"
            :nudge-width="400"
            :max-width="400"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                @click="showFiltro = true"
                text
                v-bind="attrs"
                v-on="on"
              >
                Filtrar
              </v-btn>
            </template>

            <v-card class="overflow-y-auto">
              <v-row justify="center" style="padding-bottom: 20px">
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-h6">Filtros</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>      
                </v-list>
              </v-row>
              <v-divider></v-divider>

              <v-list>
                <v-list-group 
                  no-action
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Ocupación</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-virtual-scroll
                    :items="ocupaciones"
                    height="210"
                    item-height="35"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item
                        :key="item.nom_ocupacion"
                        style="padding-left: 10px;"
                      > 
                        <!--v-list-item-content-->
                          <v-list-item-action style="padding-left: 10px;">
                            <v-checkbox
                              v-model="item.check"
                              @click="onCheckClick(1, item)"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-title>{{item.nom_ocupacion}}</v-list-item-title>
                        <!--/v-list-item-content-->
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                </v-list-group>

                <v-list-group 
                  no-action
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Casa de Dios</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-virtual-scroll
                    :items="congregaciones"
                    height="210"
                    item-height="35"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item
                        :key="item.nom_congregacion"
                        style="padding-left: 10px;"
                      > 
                        <!--v-list-item-content-->
                          <v-list-item-action style="padding-left: 10px;">
                            <v-checkbox
                              v-model="item.check"
                              @click="onCheckClick(2, item)"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-title>{{item.nom_congregacion}}</v-list-item-title>
                        <!--/v-list-item-content-->
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                </v-list-group>

                <v-list-group 
                  no-action
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Ministerio</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-virtual-scroll
                    :items="ministerios"
                    height="210"
                    item-height="35"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item
                        :key="item.nom_ministerio"
                        style="padding-left: 10px;"
                      > 
                        <!--v-list-item-content-->
                          <v-list-item-action style="padding-left: 10px;">
                            <v-checkbox
                              v-model="item.check"
                              @click="onCheckClick(3, item)"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-title>{{item.nom_ministerio}}</v-list-item-title>
                        <!--/v-list-item-content-->
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                </v-list-group>

                <v-list-item>
                  <v-card
                    class="overflow-y-auto"
                    outlined
                    :height="150"
                    :width="400"
                  >
                    <v-row no-gutters>
                      <v-col
                        v-for="filtro in filtros"
                        :key="filtro.nombre"
                      >
                        <v-chip
                          class="ma-2"
                          close
                          color="grey"
                          outlined
                          @click:close="closeChip(filtro)"
                        >
                          {{filtro.nombre}}
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-list-item>
              </v-list>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  text
                  @click="limpiar()"
                >
                  Borrar Todo
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="getMiembros()"
                >
                  Aplicar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>
        <v-spacer></v-spacer>
        <v-col style="text-align:center;">
        <v-text-field
          v-model="searchMiembros"
          append-icon="mdi-account-search"
          label="Buscar Miembros"
          single-line
          hide-details
        ></v-text-field>
        </v-col>
        <v-col style="text-align:end;">
          <v-container>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" 
                  fab 
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="addMiembro()"
                >
                  <v-icon>mdi-account-multiple-plus</v-icon>
                </v-btn>
              </template>
              <span>Agregar Miembro</span>
            </v-tooltip>
          </v-container>
        </v-col>
      </v-toolbar>
    </template>
    <v-data-table
      :headers="headers"
      :items="miembros"
      :search="searchMiembros"
      hide-default-footer
      sort-by="nom_apellidos"
      class="elevation-1"
      :loading="loadTable"
      loading-text="Cargando... Por Favor Espera"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      no-data-text="No hay Miembros para mostrar."
    >
      
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              color="teal"
              dark
              x-small
              fab
              v-bind="attrs"
              v-on="on"
              @click="viewMiembro(item)"
            >
              <v-icon>mdi-account-circle</v-icon>
            </v-btn>
          </template>
          <span>Ver</span>
        </v-tooltip>
        
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              color="cyan"
              dark
              x-small
              fab
              v-bind="attrs"
              v-on="on"
              @click="editMiembro(item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
       
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1"
              color="error"
              dark
              x-small
              fab
              v-bind="attrs"
              v-on="on"
              @click="deleteMiembro(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Dar de Baja</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination
        v-model="page"
        :length="pageCount"
        circle
        :total-visible="9"
      ></v-pagination>
    </div>
    <add-miembro></add-miembro>
    <remove-miembro></remove-miembro>
  </div>
</template>

<script>
  import axios from 'axios'
  import addMiembro from './AddMiembro.vue'
  import removeMiembro from './RemoveMiembro.vue'

  export default {
    props: {
      totalMiembros: {
        //type: Number,
        default: 0
      }
    },

    data: () => ({
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      loadEmpty: true,
      headers: [
        { text: 'Apellidos', value: 'nom_apellidos'},
        { text: 'Nombre', align: 'left', value: 'nom_miembro'},
        { text: 'Edad', value: 'num_edad', sortable: false },
        { text: 'Ministerio', value: 'nom_ministerio', sortable: false },
        { text: 'Celular', value: 'num_celular', sortable: false },
        { text: '', value: 'actions', sortable: false, align: 'end' },
      ],
      miembros: [],
      miembro: {},
      searchMiembros: '',
      isView: false,
      isEdit: false,
      loadTable: false,
      showAlert: false,
      typeAlert: '',
      alertText: '',
      showFiltro: false,
      ocupaciones: [],
      congregaciones: [],
      ministerios: [],
      filtros: [],
      url: process.env.VUE_APP_MIEMBROS_API,
      urlCatalogos: process.env.VUE_APP_CATALOGOS_API
    }),

    created () {
      var self = this

      this.getMiembros()
      this.getOcupaciones()
      this.getCongregaciones()
      this.getMinisterios()

      this.$root.$on("addModalClosed",function (Params) {
        self.miembro = {}
        self.filtros = []
        self.getMiembros() 
        if(!Params.success){
            self.viewAlert("error", Params.message)
        }
      })

      this.$root.$on("removeMiembroModalClosed",function (Params) {  
        self.miembro = {}
        self.filtros = []
        self.getMiembros()  
        if(!Params.success){
            self.viewAlert("error", Params.message)
        }
      })

    },

    components:{
      "add-miembro":addMiembro,
      "remove-miembro": removeMiembro
    },

    watch: {
      showAlert () {
        var self = this
        if(self.showAlert){
          setTimeout(function(){
            self.showAlert = false;
          }, 5000);
        }
      }
    },

    methods: {
      showAddModal() {
        var self = this
        var Params = {
          show: true, 
          isView: self.isView,
          isEdit: self.isEdit,
          miembro: self.miembro
        }
        
        this.$root.$emit("addModal", Params)
      },

      getMiembros () {
        var self = this

        self.loadTable = true
        
        if(self.filtros.length == 0){
          axios.get(self.url+'miembros', {
            headers: {
              'Authorization': `Bearer ${localStorage.sessionID}`
            }
          })
          .then(function (response) {
            self.miembros = response.data.data
            self.$emit('update:totalMiembros', self.miembros.length)
            self.loadTable = false
          })
          .catch(function (error) {
            alert(error);
          });
        }else{
          
          axios.post(self.url+'miembrosFilter', self.filtros, {
            headers: {
              'Authorization': `Bearer ${localStorage.sessionID}`
            }
          })
          .then(function (response) {
            self.miembros = response.data.data
            self.loadTable = false
          })
          .catch(function (error) {
            alert(error);
          });
        }
        self.showFiltro = false
      },

      addMiembro () {
        this.isView = false
        this.isEdit = false
        this.miembro = {}

        this.showAddModal()
      },

      viewMiembro (miembro) {
        this.isView = true
        this.isEdit = false
        this.miembro = miembro

        this.showAddModal()
      },

      editMiembro (miembro) {
        this.isView = false
        this.isEdit = true
        this.miembro = miembro

        this.showAddModal()
      },

      deleteMiembro (miembro) {
        var Params = {
          show: true,
          nomMiembro: miembro.nom_miembro,
          idMiembro: miembro.id
        }
        
        this.$root.$emit("removeMiembroModal", Params)
      },

      viewAlert (type, text){
        this.typeAlert = type
        this.alertText = text
        this.showAlert = true
      },

      getOcupaciones (){
        var self = this

        self.loadTable = true

        axios.get(self.urlCatalogos+'ocupaciones', {
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
        .then(function (response) {
          self.ocupaciones = response.data.data
        })
        .catch(function (error) {
          alert(error);
        });
      },

      getCongregaciones (){
        var self = this

        self.loadTable = true

        axios.get(self.urlCatalogos+'congregaciones', {
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
        .then(function (response) {
          self.congregaciones = response.data.data
        })
        .catch(function (error) {
          alert(error);
        });
      },

      getMinisterios (){
        var self = this

        self.loadTable = true

        axios.get(self.urlCatalogos+'ministerios', {
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
        .then(function (response) {
          self.ministerios = response.data.data
        })
        .catch(function (error) {
          alert(error);
        });
      },

      onCheckClick (type, item){
        var index = 0
        var tipo, nombre, id
        var self = this

        switch(type){
          case 1:
            index = item.check ? self.filtros.findIndex(filtro => filtro.id === item.id) : 0
            tipo = type
            nombre = item.nom_ocupacion
            id = item.id
            break;
          case 2:
            index = item.check ? self.filtros.findIndex(filtro => filtro.id === item.id) : 0
            tipo = type
            nombre = item.nom_congregacion
            id = item.id
            break;
          case 3:
            index = item.check ? self.filtros.findIndex(filtro => filtro.id === item.id) : 0
            tipo = type
            nombre = item.nom_ministerio
            id = item.id
            break;
        }

        if(item.check){
          self.filtros.push({tipo: tipo, nombre: nombre, id: id})
        }else{
          self.filtros.splice(index, 1)
        }
        
      },

      closeChip (item){
        var self = this
        var index = self.filtros.findIndex(filtro => filtro.id === item.id)
        var obj
            
        self.filtros.splice(index, 1)
        if(item.tipo == 1){
          obj = self.ocupaciones.find(ocupacion => ocupacion.id == item.id)
          obj.check = false
        }else if(item.tipo == 2){
          obj = self.congregaciones.find(congregacion => congregacion.id == item.id)
          obj.check = false
        }else if(item.tipo == 3){
          obj = self.ministerios.find(ministerio => ministerio.id == item.id)
          obj.check = false
        }

      },

      limpiar (){
        var self = this

        self.filtros = []
        
        self.ocupaciones.forEach(ocupacion => {
          if(ocupacion.check){
            ocupacion.check = false
          }
        })
        self.ministerios.forEach(ministerio => {
          if(ministerio.check){
            ministerio.check = false
          }
        })
        self.congregaciones.forEach(congregacion => {
          if(congregacion.check){
            congregacion.check = false
          }
        })
        self.getMiembros()
        self.showFiltro = false
      }

    }

  }
</script>
