<template>
  <v-dialog v-model="show" persistent max-width="500">
    <v-card>
      <v-card-title class="headline">
        ¿Está Seguro que se envío la Felicitación?
      </v-card-title>
      <v-card-text>
        El confirmar el envío usted asegura que haya sido enviada la notificación al Miembro. 
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="decline"
        >
          No
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="accept"
        >
          Si
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import axios from 'axios'

export default {
  name: 'SendConfirm',

  data: () => ({
    show: false,
    id: 0,
    tipo: 0,
    mensaje: '',
    numero: '',
    url: process.env.VUE_APP_NOTIFICACIONES_API
  }),

  created(){
    var self = this

      this.$root.$on("sendConfirmModal",function (Params) {
        self.id = Params.id,
        self.show = Params.show,
        self.tipo = Params.tipo,
        self.mensaje = Params.mensaje,
        self.numero = Params.numero
      })
  },

  methods: {

    decline () {
      this.show = false
    },

    accept () {
      var self = this
      var Params = {
        id_miembro: self.id,
        id_tipoContacto: self.tipo,
        des_mensaje: self.mensaje,
        num_celular: self.numero
      }
      
      axios.post(self.url+'mensajes', Params, {
        headers: {
          'Authorization': `Bearer ${localStorage.sessionID}`
        }
      })
      .then(function (response) {
        self.$root.$emit("sendConfirmModalClosed", response.data)
      })
      .catch(function (error) {
        var Parameters = {
          success: false,
          message: "El Mensaje no pudo ser grabado, contacte a su Administrador.  Error: ".error,
        }
        self.$root.$emit("sendConfirmModalClosed", Parameters)
      });
    
      self.decline()
    }
  }
};
</script>