<template>
  <div>
    <template>
      <v-alert
        v-if="showAlert"
        dense
        outlined
        :type="typeAlert"
        transition="scroll-y-transition"
        border="left"
        elevation="2"
      >
        {{alertText}}
      </v-alert>
      <v-toolbar flat color="white">
          <v-spacer></v-spacer>
          <v-col style="text-align:center;">
            <v-text-field
              v-model="searchMiembros"
              append-icon="mdi-account-search"
              label="Buscar Jóven"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col style="text-align:end;">
            <v-container>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" 
                    fab 
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="preRegistrar"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Registrar</span>
              </v-tooltip>
            </v-container>
          </v-col>
      </v-toolbar>
    </template>
    <div>
      <v-data-table v-show="showTable"
        :headers="headers"
        :items="miembros"
        :loading="loadTable"
        :search="searchMiembros"
        loading-text="Cargando... Por Favor Espera"
        hide-default-footer
        class="elevation-1"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        no-data-text="No hay Miembros Pendientes de Cooperación."
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-1"
                dark
                x-small
                fab
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="addCooperacion(item)"
              >
                <v-icon>mdi-cash-plus</v-icon>
              </v-btn>
            </template>
            <span>Agregar Cooperación</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <div class="text-center pt-2" v-show="showTable">
      <v-pagination
        v-model="page"
        :length="pageCount"
        circle
      ></v-pagination>
    </div>
    <add-cooperacion></add-cooperacion>
    <preRegistrar-miembro></preRegistrar-miembro>
    <!--send-confirm></send-confirm-->
  </div>
</template>

<script>
import axios from 'axios'
import Echo from 'laravel-echo'
window.Pusher = require('pusher-js')
import addCooperacion from './AddCooperacion.vue'
import preRegistrarMiembro from './PreRegistrar'

  export default {
    props: {
      usuario: {
        //type: Number,
        default: ''
      }
    },

    data: () => ({
      showAlert: false,
      typeAlert: '',
      searchMiembros: '',
      alertText: '',
      miembros: [],
      showTable: false,
      loadTable: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        { text: 'Nombre', align: 'start', value: 'nom_miembro' },
        { text: 'Apellidos', align: 'start', value: 'nom_apellido' },
        { text: 'Edad', align: 'start', value: 'edad' },
        { text: 'Casa de Dios', align: 'start', value: 'nom_congregacion' },
        { text: '', value: 'actions', sortable: false, align: 'end' },
      ],
      url: process.env.VUE_APP_REGISTRO_API
    }),

    components:{
      "preRegistrar-miembro":preRegistrarMiembro,
      "add-cooperacion":addCooperacion,
    },

    created(){
      var self = this
      

      self.$root.$on("preRegistrarModalClosed",function (Parameters) {
        if(Parameters.success){
          self.getMiembrosPendientes()
        }else{
          self.viewAlert("error", Parameters.message)
        }
      })

      self.$root.$on("addCooperacionModalClosed",function (Parameters) {
        if(Parameters.success){
         // self.getMiembrosPendientes()
        }else{
          self.viewAlert("error", Parameters.message)
        }
      })
      
    },

    mounted(){
      var self = this

      self.showTable = true
      self.loadTable = true
      self.getMiembrosPendientes();

      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: '686e1e87df22ea2aa66c',
        wsHost: process.env.VUE_APP_WSHOST,
        encrypted: true,
        forceTLS: true,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
      })

      window.Echo.channel('registro').listen('.App\\Events\\NuevoMiembro', (e) => {
        if(e.flag_cooperacion != 2){
            self.miembros.push({id: e.id, nom_miembro: e.nom_miembro, nom_apellido: e.nom_apellido, edad: e.edad, nom_congregacion: e.congregacion, flag_cooperacion: e.flag_cooperacion})
        }
      })

      window.Echo.channel('registro').listen('.App\\Events\\DeleteMiembro', (e) => {
        var idTmp;
        
        if(e.id == 0){
          self.miembros = []
        }else{
          idTmp = e.id.split(',');
          idTmp.forEach(id => {
            var i = self.miembros.findIndex(miembro => miembro.id == id)
            self.miembros.splice( i, 1 );
          })
        }
      })
    },

    watch: {
      showAlert () {
        var self = this
        if(self.showAlert){
          setTimeout(function(){
            self.showAlert = false;
          }, 3000);
        }
      }
    },

    methods: {
      getMiembrosPendientes (){
        var self = this

        axios.get(self.url+'registro', {
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
        .then(function (response) {
          self.miembros = response.data.data

          self.loadTable = false
        })
        .catch(function (error) {
          self.viewAlert('error', error);
        });
      },

      preRegistrar (){
        var self = this

        var Params = {
          show: true,
          congregacion: {id: 0},
          isEdit: false,
          isReg: true
        }
        
        self.$root.$emit("preRegistrarModal", Params)
      },

      addCooperacion (miembro){
        var self = this

        var Params = {
          show: true,
          id: miembro.id,
          idCooperacion: miembro.flag_cooperacion
        }
        
        self.$root.$emit("addCooperacionModal", Params)
      },

      viewAlert (type, text){
        this.typeAlert = type
        this.alertText = text
        this.showAlert = true
      }
    }
  }
</script>