<template>
  <v-dialog v-model="show" persistent max-width="1200px">
    <v-skeleton-loader
      v-if="loading"
      type="list-item-avatar, divider, list-item-three-line, list-item-two-line, list-item-three-line, list-item-two-line, actions"
      :loading="loading && loadMiembro"
    ></v-skeleton-loader>
    <v-card
      v-if="!loading"
      :loading="loading"
    >
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <v-img v-if="imagen"
                  :src='urlImagen+"/"+imagen+".jpg"'
                  aspect-ratio="1"
                  class="grey lighten-2"
                >
                <!--v-img v-if="imagen"
                  :src='"../../../storage/img/"+imagen+".jpg"'
                  aspect-ratio="1"
                  class="grey lighten-2"
                -->
                  <template v-slot v-if="loadingImage">
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular indeterminate :size="50" color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                  <v-row v-if="!disabled" no-gutters class="fill-height ma-0" align="end" justify="center">
                    <v-col>
                      <v-card
                        outlined
                        tile
                        align="center"
                        style="opacity: 50%"
                        @click="loadImage"
                      >
                        <v-icon>mdi-camera</v-icon>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-img>
                <p v-show="showImgReq" style="color:#FF0000;">La Imagen es requerida</p>
              </v-col>
              <v-col
                cols="12"
                md="10"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="apellidoPat"
                      label="Apellido Paterno"
                      :disabled="disabled"
                      :error-messages="apellidoPatErrors"
                      :counter="80"
                      @input="$v.apellidoPat.$touch()"
                      @blur="$v.apellidoPat.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="apellidoMat"
                      label="Apellido Materno"
                      :disabled="disabled"
                      :error-messages="apellidoMatErrors"
                      :counter="80"
                      @input="$v.apellidoMat.$touch()"
                      @blur="$v.apellidoMat.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="name"
                      label="Nombre(s)"
                      :disabled="disabled"
                      :error-messages="nameErrors"
                      :counter="200"
                      @input="$v.name.$touch()"
                      @blur="$v.name.$touch()"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fecNacimiento"
                          label="Fecha de Nacimiento"
                          persistent-hint
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          :error-messages="fecNacimientoErrors"
                          @input="$v.fecNacimiento.$touch()"
                          @blur="$v.fecNacimiento.$touch()"
                          :disabled="disabled"
                          readonly
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fecNacimiento"
                        :active-picker.sync="activePicker"
                        :max="new Date().toISOString().substr(0, 10)"
                        min="1900-01-01"
                        :first-day-of-week="0"
                        @input="menu = false"
                        locale="es-mx"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-combobox
                      v-model ="edoCivil"
                      :items ="catalogos.estadosCiviles"
                      label="Estado Civil"
                      item-text="nom_estadoCivil"
                      item-value="id"
                      :disabled="disabled"
                      :error-messages="edoCivilErrors"
                      @input="$v.edoCivil.$touch()"
                      @blur="$v.edoCivil.$touch()"
                    ></v-combobox>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <!--v-radio-group 
                      v-model="sexo" 
                      :disabled="disabled"
                      :error-messages="sexoErrors"
                      @input="$v.sexo.$touch()"
                      @blur="$v.sexo.$touch()"
                    >
                      <v-radio label="Masculino" value = 1></v-radio>
                      <v-radio label="Femenino" value = 2></v-radio>
                    </v-radio-group-->
                    <v-combobox
                      v-model ="sexo"
                      :items ="generos"
                      label="Sexo"
                      item-text="nom_genero"
                      item-value="id"
                      :disabled="disabled"
                      :error-messages="sexoErrors"
                      @input="$v.sexo.$touch()"
                      @blur="$v.sexo.$touch()"
                    ></v-combobox>
                  </v-col>
                </v-row>
              </v-col>
            
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="domCalle"
                  label="Domicilio (Calle)"
                  :disabled="disabled"
                  :error-messages="domCalleErrors"
                  :counter="250"
                  @input="$v.domCalle.$touch()"
                  @blur="$v.domCalle.$touch()"
                ></v-text-field>
              </v-col>
               <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="domNumero"
                  label="Domicilio (Número)"
                  :disabled="disabled"
                  :error-messages="domNumeroErrors"
                  :counter="10"
                  @input="$v.domNumero.$touch()"
                  @blur="$v.domNumero.$touch()"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="cp"
                  label="C.P"
                  :disabled="disabled"
                  append-icon="mdi-magnify"
                  @keyup.enter="searchColonias"
                  @click:append="searchCP"
                  :error-messages="cpErrors"
                  @input="$v.cp.$touch()"
                  @blur="$v.cp.$touch()"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-select
                  v-model="pais"
                  label="País"
                  item-text="nom_pais"
                  item-value="id"
                  :items="catalogosLocalizacion.paises"
                  disabled
                  :error-messages="paisErrors"
                  @input="$v.pais.$touch()"
                  @blur="$v.pais.$touch()"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-select
                  v-model="estado"
                  label="Estado"
                  item-text="nom_estado"
                  item-value="id"
                  :items="catalogosLocalizacion.estados"
                  disabled
                  :error-messages="estadoErrors"
                  @input="$v.estado.$touch()"
                  @blur="$v.esatado.$touch()"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-select
                  v-model="municipio"
                  label="Ciudad"
                  item-text="nom_municipio"
                  item-value="id"
                  :items="catalogosLocalizacion.municipios"
                  disabled
                  :error-messages="municipioErrors"
                  @input="$v.municipio.$touch()"
                  @blur="$v.municipio.$touch()"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-combobox
                  v-model="colonia"
                  label="Colonia"
                  item-text="nom_colonia"
                  item-value="id"
                  :items="catalogosLocalizacion.colonias"
                  :disabled="disabledColonia"
                  no-data-text="Escriba su Código Postal"
                  :error-messages="coloniaErrors"
                  @input="$v.colonia.$touch()"
                  @blur="$v.colonia.$touch()"
                ></v-combobox>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="correo"
                  label="Correo Electrónico"
                  :disabled="disabled"
                  :counter="200"
                  :error-messages="correoErrors"
                  @input="$v.correo.$touch()"
                  @blur="$v.correo.$touch()"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="numTelefono"
                  label="Teléfono"
                  :counter="10"
                  :disabled="disabled"
                  :error-messages="numTelefonoErrors"
                  @input="$v.numTelefono.$touch()"
                  @blur="$v.numTelefono.$touch()"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="numCelular"
                  label="Celular"
                  :disabled="disabled"
                  :counter="10"
                  :error-messages="numCelularErrors"
                  @input="$v.numCelular.$touch()"
                  @blur="$v.numCelular.$touch()"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <!--p>Contacto: </p>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-radio-group 
                  v-model="tipoContacto" 
                  :disabled="disabled"
                >
                  <v-radio label="Whatsapp" value = 1></v-radio>
                  <v-radio label="SMS" value = 2></v-radio>
                </v-radio-group-->
                <v-combobox
                  v-model ="tipoContacto"
                  :items ="tiposContacto"
                  label="Tipo de Contacto"
                  item-text="nom_tipo"
                  item-value="id"
                  :disabled="disabled"
                  :error-messages="tipoContactoErrors"
                  @input="$v.tipoContacto.$touch()"
                  @blur="$v.tipoContacto.$touch()"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <v-combobox
                  v-model ="ministerio"
                  :items ="catalogos.ministerios"
                  label="Ministerio y/o Comisión"
                  item-text="nom_ministerio"
                  item-value="id"
                  :disabled="disabled"
                ></v-combobox>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-combobox
                  v-model ="congregacion"
                  :items ="catalogos.congregaciones"
                  label="Casa de Dios a la que pertenece"
                  item-text="nom_congregacion"
                  item-value="id"
                  :disabled="disabled"
                  :error-messages="congregacionErrors"
                  @input="$v.congregacion.$touch()"
                  @blur="$v.congregacion.$touch()"
                ></v-combobox>
              </v-col>
              <v-col
                cols="12"
                md="3"
                align="center"
                justify="center"
              >
                <v-checkbox
                  v-model="bautizado"
                  label="Bautizado/a"
                  :disabled="disabled"
                ></v-checkbox>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-combobox
                  id="ocupacionCombo"
                  v-model ="ocupacion"
                  :items ="catalogos.ocupaciones"
                  label="Ocupación"
                  item-text="nom_ocupacion"
                  item-value="id"
                  :disabled="disabled"
                  allow-overflow
                  :error-messages="ocupacionErrors"
                  @input="$v.ocupacion.$touch()"
                  @blur="$v.ocupacion.$touch()"
                >
                  <template v-slot:append-item>
                    <v-divider></v-divider>
                    <v-list-item @click="addOcupaciones">
                      <v-list-item-content>
                        <v-list-item-title>
                          Agregar Ocupación
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <search-cp></search-cp>
            <webCam-modal></webCam-modal>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions v-if="isView != 1">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
        <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
    <add-catalogo></add-catalogo>
  </v-dialog>
</template>


<script>
import axios from 'axios'
import searchCP from './SearchCP.vue'
import webCam from './WebCamModal.vue'
import addCatalogo from '../catalogos/AddCatalogo.vue'

import { validationMixin } from 'vuelidate'
import { required, maxLength, minLength, numeric, email } from 'vuelidate/lib/validators'

export default {
  name: 'AddMiembro',

  mixins: [validationMixin],

  validations() {
    if (this.edad >= 6) {
      return {
        name: { required, maxLength: maxLength(200) },
        apellidoPat: { required, maxLength: maxLength(80) },
        apellidoMat: { required, maxLength: maxLength(80) },
        fecNacimiento: { required },
        edoCivil: { required },
        sexo: { required },
        domCalle: { required, maxLength: maxLength(250) },
        domNumero: { required, maxLength: maxLength(10) },
        pais: { required },
        estado: { required },
        municipio: { required },
        colonia: { required },
        cp: { required },
        numCelular: { maxLength: maxLength(10), minLength: minLength(10), numeric, required },
        numTelefono: { maxLength: maxLength(10), minLength: minLength(10), numeric },
        correo: { email },
        tipoContacto: { required },
        congregacion: { required },
        ocupacion: { required }
      }
    }else{
      return {
        name: { required, maxLength: maxLength(200) },
        apellidoPat: { required, maxLength: maxLength(80) },
        apellidoMat: { required, maxLength: maxLength(80) },
        fecNacimiento: { required },
        edoCivil: { required },
        sexo: { required },
        domCalle: { required, maxLength: maxLength(250) },
        domNumero: { required, maxLength: maxLength(10) },
        pais: { required },
        estado: { required },
        municipio: { required },
        colonia: { required },
        cp: { required },
        numCelular: { maxLength: maxLength(10), minLength: minLength(10), numeric, required },
        numTelefono: { maxLength: maxLength(10), minLength: minLength(10), numeric },
        correo: { email },
        tipoContacto: { required },
        congregacion: { required }
      }
    }
  },

  data: () => ({
    disabledColonia: false,
    //loading: false,
    loadingImage: false,
    catalogos: {
      estadosCiviles: [],
      congregaciones: [],
      ocupaciones: [],
      ministerios: []
    },
    catalogosLocalizacion:{
      paises: [],
      estados: [],
      municipios: [],
      colonias: []
    },
    generos: [
      {
        id: 1,
        nom_genero: 'Hombre'
      },
      {
        id: 2,
        nom_genero: 'Mujer'
      }
    ],
    tiposContacto: [
      {
        id: 1,
        nom_tipo: 'Whatsapp'
      },
      {
        id: 2,
        nom_tipo: 'SMS'
      }
    ],
    apellidoPat: '',
    apellidoMat: '',
    name: '',
    fecNacimiento: '',
    edoCivil: '',
    sexo: '',
    domCalle: '',
    domNumero: '',
    cp: '',
    pais: 0,
    estado: 0,
    municipio: 0,
    colonia: '',
    numTelefono: '',
    numCelular: '',
    tipoContacto: '',
    ministerio: '',
    congregacion: '',
    bautizado: false,
    ocupacion: '',
    correo: '',
    imagen: '',
    idMiembro: 0,
    menu: false,
    show: false,
    isView: false,
    isEdit: false,
    isSave: false,
    activePicker: null,
    loadEdoCivil: false,
    loadMinisterio: false,
    loadCongregacion: false,
    loadPais: false,
    loadEstado: false,
    loadCiudad: false,
    loadCP: false,
    loadColonias: false,
    loadMiembro: false,
    loadOcupacion: false,
    idColonia : 0,
    idMinisterio : 0,
    idEdoCivil : 0,
    idOcupacion : 0,
    idCongregacion: 0,
    urlMiembros: process.env.VUE_APP_MIEMBROS_API,
    urlCatalogos: process.env.VUE_APP_CATALOGOS_API,
    urlImagen: process.env.VUE_APP_IMAGEN,
    urlLocalizacion: process.env.VUE_APP_LOCALIZACIONES_API
  }),

  created(){
    var self = this
    this.loadCatalogs()

    this.$root.$on("addModal",function (Parameters) {
      self.show = Parameters.show
      self.isView = Parameters.isView
      self.isEdit = Parameters.isEdit
      self.disabledColonia = true
      
      if(self.isView || self.isEdit){
        //self.loading = true
        self.apellidoPat = Parameters.miembro.nom_apellidoPaterno
        self.apellidoMat = Parameters.miembro.nom_apellidoMaterno
        self.name = Parameters.miembro.nom_miembro
        self.fecNacimiento = Parameters.miembro.fec_nacimiento
        self.domCalle = Parameters.miembro.dom_calle
        self.domNumero = Parameters.miembro.dom_numero
        self.cp = Parameters.miembro.codigo_postal
        //self.pais = Parameters.miembro.idPais
        //self.estado = Parameters.miembro.idEstado
        //self.municipio = Parameters.miembro.idMunicipio
        self.numTelefono = Parameters.miembro.num_telefono
        self.numCelular = Parameters.miembro.num_celular
        self.correo = Parameters.miembro.des_correo
        //self.casaMisericordia = Parameters.miembro.idCasaMisericordia
        self.bautizado = parseInt(Parameters.miembro.bautizado, 10)
        self.idMiembro = Parameters.miembro.id
        self.imagen = Parameters.miembro.des_imagen
        self.tipoContacto = self.tiposContacto.find(tipo => tipo.id == Parameters.miembro.id_tipoContacto)
        self.sexo = self.generos.find(genero => genero.id == Parameters.miembro.id_genero)
        
        if(self.cp){
          self.loadCP = true
          self.searchColonias()
        
        
          if(self.isView){
            self.disabledColonia = true
          }
          self.loadCP = false
        }

        self.idMinisterio = Parameters.miembro.id_ministerio
        self.idEdoCivil = Parameters.miembro.id_estadoCivil
        self.idOcupacion = Parameters.miembro.id_ocupacion
        self.idCongregacion =Parameters.miembro.id_congregacion
        self.idColonia = Parameters.miembro.id_colonia

      }

      if(!self.imagen){
        self.imagen = 'undefined'
      }

      self.$v.$reset()
    })

    this.$root.$on("cpModalClose",function (Parameters) {
      self.catalogosLocalizacion = Parameters.catalogos
      self.pais = Parameters.idPais
      self.estado = Parameters.idEstado
      self.municipio = Parameters.idMunicipio
      //self.colonia = Parameters.idColonia
      self.colonia = self.catalogosLocalizacion.colonias.find(colonia => colonia.id == Parameters.idColonia)
      self.cp = Parameters.numCP
    })

    this.$root.$on("webCamClosed",function (Parameters) {
      self.loadingImage = true
      setTimeout(() => {
        self.imagen = Parameters
        self.loadingImage = false
      }, 3000);
    })

    this.$root.$on("addModalCatalogOcupacionesClosed",function (Params) {
      self.ocupacion = ''
      if(!Params.success){
        //self.viewAlert("error", Params.message)
      }
      self.loadOcupaciones()
    })
  },

  components:{
    "search-cp":searchCP,
    "webCam-modal":webCam,
    "add-catalogo": addCatalogo
  },

  computed: {
    disabled (){
      return this.isView ? true : false
    },

    loading (){
      if(this.loadEdoCivil || this.loadMinisterio || this.loadCongregacion || this.loadPais || this.loadEstado || this.loadCiudad || this.loadColonias || this.loadOcupacion){
        return true
      }else{
        return false
      }
    },
    
    formTitle () {
      return this.isEdit ? 'Editar Miembro' : this.isView ? 'Miembro' : 'Nuevo Miembro'
    },

    nameErrors () {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.maxLength && errors.push('Nombre debe tener máximo 50 caracteres')
      !this.$v.name.required && errors.push('Nombre es requerido')
      return errors
    },

    apellidoPatErrors () {
      const errors = []
      if (!this.$v.apellidoPat.$dirty) return errors
      !this.$v.apellidoPat.maxLength && errors.push('El Apellido debe tener máximo 25 caracteres')
      !this.$v.apellidoPat.required && errors.push('Apellido Paterno es requerido')
      return errors
    },

    apellidoMatErrors () {
      const errors = []
      if (!this.$v.apellidoMat.$dirty) return errors
      !this.$v.apellidoMat.maxLength && errors.push('El Apellido debe tener máximo 25 caracteres')
      !this.$v.apellidoMat.required && errors.push('Apellido Materno es requerido')
      return errors
    },

    fecNacimientoErrors() {
      const errors = []
      if (!this.$v.fecNacimiento.$dirty) return errors
      !this.$v.fecNacimiento.required && errors.push('La Fecha de Nacimiento es requerida')
      return errors
    },

    edoCivilErrors() {
      const errors = []
      if (!this.$v.edoCivil.$dirty) return errors
      !this.$v.edoCivil.required && errors.push('El Estado Civil es requerido')
      return errors
    },

    sexoErrors() {
      const errors = []
      if (!this.$v.sexo.$dirty) return errors
      !this.$v.sexo.required && errors.push('El género es requerido')
      return errors
    },

    domCalleErrors () {
      const errors = []
      if (!this.$v.domCalle.$dirty) return errors
      !this.$v.domCalle.maxLength && errors.push('El nombre de la calle debe tener máximo 50 caracteres')
      !this.$v.domCalle.required && errors.push('El nombre de la calle es requerido')
      return errors
    },

    domNumeroErrors () {
      const errors = []
      if (!this.$v.domNumero.$dirty) return errors
      !this.$v.domNumero.maxLength && errors.push('El número del domicilio debe tener máximo 10 caracteres')
      !this.$v.domNumero.required && errors.push('El número del domicilio es requerido')
      return errors
    },

    paisErrors () {
      const errors = []
      if (!this.$v.pais.$dirty) return errors
      !this.$v.pais.required && errors.push('El País es requerido')
      return errors
    },

    estadoErrors () {
      const errors = []
      if (!this.$v.estado.$dirty) return errors
      !this.$v.estado.required && errors.push('El Estado es requerido')
      return errors
    },

    municipioErrors () {
      const errors = []
      if (!this.$v.municipio.$dirty) return errors
      !this.$v.municipio.required && errors.push('El Municipio es requerido')
      return errors
    },

    coloniaErrors () {
      const errors = []
      if (!this.$v.colonia.$dirty) return errors
      !this.$v.colonia.required && errors.push('La Colonia es requerida')
      return errors
    },

    cpErrors () {
      const errors = []
      if (!this.$v.cp.$dirty) return errors
      !this.$v.cp.required && errors.push('El Código Postal es requerido')
      return errors
    },

    numCelularErrors (){
      const errors = []
      if (!this.$v.numCelular.$dirty) return errors
      !this.$v.numCelular.minLength && errors.push('El número de Celular debe tener 10 digitos')
      !this.$v.numCelular.maxLength && errors.push('El número de Celular debe tener 10 digitos')
      !this.$v.numCelular.numeric && errors.push('El número de Celular solo admite números')
      !this.$v.numCelular.required && errors.push('El número de Celular es requerido')
      return errors
    },

    numTelefonoErrors (){
      const errors = []
      if (!this.$v.numTelefono.$dirty) return errors
      !this.$v.numTelefono.minLength && errors.push('El número de Teléfono debe tener 10 digitos')
      !this.$v.numTelefono.maxLength && errors.push('El número de Teléfono debe tener 10 digitos')
      !this.$v.numTelefono.numeric && errors.push('El número de Teléfono solo admite números')
      return errors
    },

    correoErrors (){
      const errors = []
      if (!this.$v.correo.$dirty) return errors
      !this.$v.correo.email && errors.push('Favor de introducir un email válido')
      return errors
    },

    tipoContactoErrors() {
      const errors = []
      if (!this.$v.tipoContacto.$dirty) return errors
      !this.$v.tipoContacto.required && errors.push('El tipo de contacto es requerido')
      return errors
    },

    congregacionErrors () {
      const errors = []
      if (!this.$v.congregacion.$dirty) return errors
      !this.$v.congregacion.required && errors.push('La congregación es requerida')
      return errors
    },

    ocupacionErrors () {
      if(this.edad >= 6){
        const errors = []
        if (!this.$v.ocupacion.$dirty) return errors
        !this.$v.ocupacion.required && errors.push('La ocupación es requerida')
        return errors
      }
    },

    edad () {
      if(this.fecNacimiento){
        return this.calcularEdad(this.fecNacimiento)
      }else{
        return 0
      }
    },

    showImgReq() {
      if(this.imagen == 'undefined' && this.isSave){
        return true
      }else{
        return false
      }
    }
  },

  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },

    loading(val){
      if((this.isEdit || this.isView) && !val){
        this.loadMiembro = true
        this.colonia = this.catalogosLocalizacion.colonias.find(colonia => colonia.id == this.idColonia)
        this.ministerio = this.catalogos.ministerios.find(ministerio => ministerio.id == this.idMinisterio)
        this.edoCivil = this.catalogos.estadosCiviles.find(edoCivil => edoCivil.id == this.idEdoCivil)
        this.ocupacion = this.catalogos.ocupaciones.find(ocupacion => ocupacion.id == this.idOcupacion)
        this.congregacion = this.catalogos.congregaciones.find(congregacion => congregacion.id == this.idCongregacion)
        this.loadMiembro = false
      }
    }
  },

  methods: {
    close () {
      this.show = false
      this.apellidoPat = '',
      this.apellidoMat = '',
      this.name = '',
      this.fecNacimiento = '',
      this.edoCivil = '',
      this.sexo = '',
      this.domCalle = '',
      this.domNumero = '',
      this.cp = '',
      this.pais = 0,
      this.estado = 0,
      this.municipio = 0,
      this.colonia = '',
      this.numTelefono = '',
      this.numCelular = '',
      this.tipoContacto = '',
      this.ministerio = '',
      this.congregacion = '',
      this.bautizado = false,
      this.ocupacion = '',
      this.imagen = '',
      this.correo = '',
      this.isSave = false,
      this.activePicker = null,
      this.loadEdoCivil = false,
      this.loadMinisterio = false,
      this.loadCongregacion = false,
      this.loadPais = false,
      this.loadEstado = false,
      this.loadCiudad = false,
      this.loadCP = false,
      this.loadColonias = false,
      this.loadMiembro = false,
      this.loadOcupacion = false,
      this.idColonia = 0,
      this.idMinisterio = 0,
      this.idEdoCivil = 0,
      this.idOcupacion = 0,
      this.idCongregacion = 0
    },

    loadCatalogs(){
      this.loadMinisterios()
      this.loadOcupaciones()
      this.loadEstadosCiviles()
      this.loadCongregaciones()
    },

    loadMinisterios(){
      var self = this

      self.loadMinisterio = true

      axios.get(self.urlCatalogos+'ministerios', {
        headers: {
          'Authorization': `Bearer ${localStorage.sessionID}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(function (response) {
        self.catalogos.ministerios = response.data.data
        self.loadMinisterio = false
      })/*
      .catch(function (error) {
        alert(error);
      })*/;
    },

    loadOcupaciones(){
      var self = this

      self.loadOcupacion = true

      axios.get(self.urlCatalogos+'ocupaciones', {
        headers: {
          'Authorization': `Bearer ${localStorage.sessionID}`,
          'Access-Control-Allow-Origin': '*'
        }, 
      })
      .then(function (response) {
        self.catalogos.ocupaciones = response.data.data
        self.loadOcupacion = false
        //self.catalogos.ocupaciones.push({id: -1, nom_ocupacion: "Agregar Ocupación"})
      })/*
      .catch(function (error) {
        alert(error);
      })*/;
    },

    loadEstadosCiviles(){
      var self = this

      self.loadEdoCivil = true

      axios.get(self.urlCatalogos+'estadosCiviles', {
        headers: {
          'Authorization': `Bearer ${localStorage.sessionID}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(function (response) {
        self.catalogos.estadosCiviles = response.data.data
        self.loadEdoCivil = false
      })
      /*.catch(function (error) {
        alert(error);
      })*/;
    },

    loadCongregaciones(){
      var self = this

      self.loadCongregacion = true

      axios.get(self.urlCatalogos+'congregaciones', {
        headers: {
          'Authorization': `Bearer ${localStorage.sessionID}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(function (response) {
        self.catalogos.congregaciones = response.data.data
        self.loadCongregacion = false
      })
      /*.catch(function (error) {
        alert(error);
      })*/;
    },

    searchCP(){
      var self = this
        
      var Params = {
        show: true
      }
        
      self.$root.$emit("cpModal", Params)
    },

    searchColonias(){
      var self = this
      var Params = {
        "codigo_postal": self.cp
      }

      self.loadColonias = true

      self.colonia = ''
      self.idColonia = 0

      axios.post(self.urlLocalizacion+'cp', Params, {
        headers: {
          'Authorization': `Bearer ${localStorage.sessionID}`,
          'Access-Control-Allow-Origin': '*'
        }, 
      })
      .then(function (response) {
        self.catalogosLocalizacion.paises = [{id: response.data.data.datosLocalizacion[0].id_pais, nom_pais: response.data.data.datosLocalizacion[0].nom_pais}]
        self.catalogosLocalizacion.estados = [{id: response.data.data.datosLocalizacion[0].id_estado, nom_estado: response.data.data.datosLocalizacion[0].nom_estado}]
        self.catalogosLocalizacion.municipios = [{id: response.data.data.datosLocalizacion[0].id_municipio, nom_municipio: response.data.data.datosLocalizacion[0].nom_municipio}]
        self.pais = response.data.data.datosLocalizacion[0].id_pais
        self.estado = response.data.data.datosLocalizacion[0].id_estado
        self.municipio = response.data.data.datosLocalizacion[0].id_municipio
        self.catalogosLocalizacion.colonias = response.data.data.colonias
        if(!self.isView){
          self.disabledColonia = false
        }
        self.loadColonias = false
      })
      /*.catch(function (error) {
        alert(error);
      })*/;
    },

    loadImage () {
      var Params = {
        show: true
      }

      this.$root.$emit("webCamModal", Params)
    },

    save () {
      var self = this

      
      self.isSave = true
      self.$v.$touch()

      if(!self.$v.$invalid && !self.showImgReq){
        var Params = {
          id_miembro: self.idMiembro,
          id_ministerio: !self.ministerio ? 0 : self.ministerio.id ? self.ministerio.id : 0 ,
          id_pais: self.pais,
          id_estado: self.estado,
          id_municipio: self.municipio,
          id_colonia: self.colonia.id,
          id_estadoCivil: self.edoCivil.id,
          id_ocupacion: !self.ocupacion ? 0 : self.ocupacion.id ? self.ocupacion.id : 0,
          id_tipoContacto: self.tipoContacto.id,
          id_genero: self.sexo.id,
          bautizado: self.bautizado,
          nom_miembro: self.name,
          nom_apellidoPaterno: self.apellidoPat,
          nom_apellidoMaterno: self.apellidoMat,
          fec_nacimiento: self.fecNacimiento,
          des_correo: self.correo,
          num_telefono: self.numTelefono,
          num_celular: self.numCelular,
          dom_calle: self.domCalle,
          dom_numero: self.domNumero,
          codigo_postal: self.cp,
          des_imagen: self.imagen,
          id_congregacion: !self.congregacion ? 0 : self.congregacion.id ? self.congregacion.id : 0
        }
        
        if(self.isEdit){
          axios.put(self.urlMiembros+'miembros', Params, {
            headers: {
              'Authorization': `Bearer ${localStorage.sessionID}`
            }
          })
            .then(function (response) {
              self.close()
              self.$root.$emit("addModalClosed", response.data)
            })
            .catch(function (error) {
              var Parameters = {
                success: false,
                message: "El Miembro no pudo ser editado, contacte a su Administrador.  Error: ".error
              }

              self.$root.$emit("addModalClosed", Parameters)
          });
        }else{
          axios.post(self.urlMiembros+'miembros', Params, {
            headers: {
              'Authorization': `Bearer ${localStorage.sessionID}`
            }
          })
            .then(function (response) {
              self.close()
              self.$root.$emit("addModalClosed",response.data)
            })
            .catch(function (error) {
              var Parameters = {
                success: false,
                message: "El Miembro no pudo ser creado, contacte a su Administrador.  Error: ".error
              }

              self.$root.$emit("addModalClosed", Parameters)
          });
        }
      }
      
    },

    addOcupaciones (){
      var self = this

      //if(self.ocupacion.id == -1){
        var Params = {
          show: true, 
          isEdit: false,
          type: "Ocupación",
          name: "",
          id: 0
        }
        
        self.$root.$emit("addModalCatalog", Params)
      //}
    },

    calcularEdad(fecha) {
      var hoy = new Date();
      var cumpleanos = new Date(fecha);
      var edad = hoy.getFullYear() - cumpleanos.getFullYear();
      var m = hoy.getMonth() - cumpleanos.getMonth();
  
      if (m < 0 || (m == 0 && hoy.getDate() < cumpleanos.getDate())) {
          edad--;
      }
  
      return edad;
    }
  }
};
</script>