<template>
  <v-dialog v-model="show" persistent max-width="500">
    <v-card>
      <v-card-title class="headline">
        Baja de Miembro
      </v-card-title>
      <v-card-text>
        <h3>Seleccione el motivo por el cual desea dar de baja a {{nomMiembro}}:</h3>
        <v-radio-group
          v-model="idMotivo"
          column
        >
          <v-radio
            v-for="motivo in motivos"
            :key="motivo.id"
            :label="motivo.nom_motivoBaja"
            :value="motivo.id"
          ></v-radio>
        </v-radio-group>
        <v-col
          cols="12"
          sm="6"
          md="10"
          style="margin-top: -30px"
        >
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            v-if="!disabledFecha"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fecha_fallecimiento"
                label="Seleccione la Fecha del Fallecimiento"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fecha_fallecimiento"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancelar
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(date)"
              >
                Aceptar
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="decline"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="red darken-1"
          text
          @click="accept"
          v-if="idMotivo > 0"
        >
          Dar de Baja
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import axios from 'axios'

export default {
  name: 'RemoveMiembro',

  data: () => ({
    show: false,
    motivos: [],
    idMotivo: 0,
    nomMiembro: '',
    idMiembro: 0,
    fecha_fallecimiento: '',
    menu: '',
    date: [],
    urlMiembros: process.env.VUE_APP_MIEMBROS_API,
    urlCatalogos: process.env.VUE_APP_CATALOGOS_API
  }),

  created(){
    var self = this

    axios.get(self.urlCatalogos+'bajas', {
      headers: {
        'Authorization': `Bearer ${localStorage.sessionID}`
      }
    })
    .then(function (response) {
      self.motivos = response.data.data
    })
    .catch(function (error) {
      alert(error);
    });

    this.$root.$on("removeMiembroModal",function (Parameters) {
      self.show = Parameters.show
      self.nomMiembro = Parameters.nomMiembro
      self.idMiembro = Parameters.idMiembro
      self.fecha_fallecimiento = ''
    })
  },

  computed: {
    disabledFecha (){
      return this.idMotivo!=3 ? true : false
    }
  },

  methods: {

    decline () {
      this.show = false
      this.idMiembro = 0
      this.nomMiembro = ''
      this.idMotivo = 0
    },

    accept () {
      var self = this
      var Params = {
        id: self.idMiembro,
        motivo_baja: self.idMotivo,
        fecha: self.fecha_fallecimiento
      }
      if(self.idMotivo != 3 || self.fecha_fallecimiento != ''){
        axios.delete(self.urlMiembros+'miembros', { data: Params,
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`
          }
        })
        .then(function (response) {
          self.$root.$emit("removeMiembroModalClosed", response.data)
        })
        .catch(function (error) {
          var Parameters = {
              success: false,
              message: "El Miembro no pudo ser dado de baja, contacte a su Administrador.  Error: ".error
            }

            self.$root.$emit("removeMiembroModalClosed", Parameters)
          });

        self.decline()
      }
    }
  }
};
</script>