<template>
  <v-dialog v-model="show" persistent max-width="350">
    <v-skeleton-loader
      v-if="loading"
      type="list-item-three-line, list-item-three-line, actions"
      :loading="loading"
    ></v-skeleton-loader>
    <v-card
      v-if="!loading"
      :loading="loading"
      shaped
    >
      <v-card-title>
        <span class="headline">Registrar</span>
        <v-spacer></v-spacer>
        <v-col style="text-align:end;">
          <v-btn color="primary" 
            fab 
            small
            @click="preRegistrar"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>

      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-combobox
                v-model ="congregacion"
                :items ="catalogos.congregaciones"
                label="Casa de Dios a la que pertenece"
                item-text="nom_congregacion"
                item-value="id"
                :error-messages="congregacionErrors"
                @input="$v.congregacion.$touch()"
                @blur="$v.congregacion.$touch()"
                @change="loadMiembrosCongregacion()"
              ></v-combobox>
            </v-row>
            <v-row v-if="loadingMiembros">
              <v-col
                align="center"
                justify="center"
              >
                 <v-progress-circular
                  :size="50"
                  :width="3"
                  color="blue"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-autocomplete
                v-model="nombre"
                label="Nombre Completo"
                :error-messages="nombreErrors"
                @input="$v.nombre.$touch()"
                @blur="$v.nombre.$touch()"
                :items="miembros"
                item-text="nom_completo"
                item-value="id"
                placeholder="Empieza a Escribir tu Nombre"
              ></v-autocomplete>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <div class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="loadingSave"
        ></v-progress-circular>
        <p class="red--text" v-if="showMessage" style="">*{{message}}</p>
      </div>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
        <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
      </v-card-actions>
    </v-card>

    <preRegistrar-miembro></preRegistrar-miembro>
  </v-dialog>
</template>


<script>
import axios from 'axios'

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import preRegistrarMiembro from './PreRegistrar'

export default {
  name: 'RegistraMiembro',

  mixins: [validationMixin],

  validations() {
      return {
        nombre: { required },
        congregacion: { required }
    }
  },

  data: () => ({
    loading: false,
    loadingMiembros: false,
    catalogos: {
      congregaciones: []
    },
    miembros: [],
    nombre: '',
    congregacion: '',
    show: false,
    showMessage: false,
    message: 'Varón sin Registro, favor de darlo de alta.',
    loadingSave: false,
    urlApi: process.env.VUE_APP_REGISTRO_API,
  }),

  components:{
      "preRegistrar-miembro":preRegistrarMiembro
    },

  created(){
    var self = this

    this.$root.$on("registrarModal",function (Parameters) {
      self.loading = true
      self.loadCatalogs()
      self.show = Parameters.show

      self.$v.$reset()
    })

    this.$root.$on("preRegistrarModalClosed",function (Parameters) {
      self.close()
      self.$root.$emit("registrarModalClosed",Parameters)
    })
    
  },

  computed: {

    nombreErrors () {
      const errors = []
      if (!this.$v.nombre.$dirty) return errors
      !this.$v.nombre.required && errors.push('Nombre es requerido')
      return errors
    },

    congregacionErrors () {
      const errors = []
      if (!this.$v.congregacion.$dirty) return errors
      !this.$v.congregacion.required && errors.push('La congregación es requerida')
      return errors
    }
  },

  methods: {
    close () {
      this.show = false
      this.nombre = ''
      this.edad = ''
      this.invitado = false
      this.showMessage = false
      this.message = ''
      this.congregacion = ''
      this.loadingSave = false
      this.loadingMiembros = false
      this.miembros = []
    },

    loadCatalogs(){
      this.loadCongregaciones();
    },

    loadCongregaciones(){
      var self = this

      axios.get(self.urlApi+'varones/congregaciones', {
        headers: {
          'Authorization': `Bearer ${localStorage.sessionID}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(function (response) {
        self.catalogos.congregaciones = response.data.data
        self.loading = false
        
        //self.congregacion = self.catalogos.congregaciones.find(congregacion => congregacion.id == 1)
      })
      /*.catch(function (error) {
        alert(error);
      })*/;
    },

    loadMiembrosCongregacion() {
      var self = this
      self.loadingMiembros = true

      axios.get(self.urlApi+'varones/preRegistro/'+self.congregacion.id, {
        headers: {
          'Authorization': `Bearer ${localStorage.sessionID}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(function (response) {
        self.miembros = response.data.data
        self.loadingMiembros = false
      })
    },

    save () {
      var self = this

      
      self.$v.$touch()

      if(!self.$v.$invalid && self.nombre != 0){
        self.loadingSave = true

        var Params = {
          id: self.nombre
        }
        
        axios.post(self.urlApi+'varones/registro', Params, {
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
          .then(function (response) {
            self.loadingSave = false

            self.close()
            self.$root.$emit("registrarModalClosed",response.data)
          })
          .catch(function (error) {
            var Parameters = {
              success: false,
              message: "El Miembro no pudo ser creado, contacte a su Administrador. "
            }

            self.close()
            self.$root.$emit("registrarModalClosed", Parameters)
        });
        
      }else if(self.nombre == 0){
        self.showMessage = true
      }
      
    },

    preRegistrar (){
      var self = this

      var Params = {
        show: true,
        congregacion: {id: 0},
        isEdit: false,
        isReg: true
      }
      
      self.$root.$emit("preRegistrarModal", Params)
    },
  }
};
</script>