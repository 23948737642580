<template>
  <v-container>
    <v-list>
      <template v-for="(item, index) in mensajes">
        <v-divider
          v-if="index != 0"
          :key="index"
        ></v-divider>
        <v-list-item :key="item.id">
          <v-list-item-content>
            <v-list-item-title class="text-h5">{{index + 1}}.- {{item.mensaje}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-container>
</template>

<script>
import axios from 'axios'
import Echo from 'laravel-echo'
window.Pusher = require('pusher-js')

  export default {
    data: () => ({
      showAlert: false,
      typeAlert: '',
      alertText: '',
      mensajes: [],
      url: process.env.VUE_APP_NOTIFICACIONES_API
    }),

    mounted(){
      var self = this

      self.getAnuncios();

      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: '686e1e87df22ea2aa66c',
        wsHost: process.env.VUE_APP_WSHOST,
        encrypted: true,
        forceTLS: true,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
      })

      window.Echo.channel('anuncio').listen('.App\\Events\\NuevoAnuncio', (e) => {
        self.mensajes.push({mensaje: e.mensaje, id: e.id})
      })

      window.Echo.channel('anuncio').listen('.App\\Events\\DeleteAnuncio', (e) => {
        var idTmp;
        
        if(e.id == 0){
          self.mensajes = []
        }else{
          idTmp = e.id.split(',');
          idTmp.forEach(id => {
            var i = self.mensajes.findIndex(mensaje => mensaje.id == id)
            self.mensajes.splice( i, 1 );
          })
        }
      })
    },

    watch: {
      showAlert () {
        var self = this
        if(self.showAlert){
          setTimeout(function(){
            self.showAlert = false;
          }, 3000);
        }
      }
    },

    methods: {
      getAnuncios (){
        var self = this

        axios.get(self.url+'anuncios', {
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
        .then(function (response) {
          self.mensajes = response.data.data
        })
        .catch(function (error) {
          self.viewAlert('error', error);
        });
      },

      viewAlert (type, text){
        this.typeAlert = type
        this.alertText = text
        this.showAlert = true
      }
    }
  }
</script>
