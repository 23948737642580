<template>
  <div>
    <template>
      <v-alert
        v-if="showAlert"
        dense
        outlined
        :type="typeAlert"
        transition="scroll-y-transition"
        border="left"
        elevation="2"
      >
        {{alertText}}
      </v-alert>
      <v-toolbar flat color="white">
        <v-col style="margin-top: auto">
          <!-- <v-combobox
            dense
            outlined
            disabled
            v-model ="periodo"
            :items ="catalogos.periodos"
            label="Periodo"
            item-text="nom_periodo"
            item-value="id"
            @change="changePeriodo()"
          >
          </v-combobox> -->
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          sm="6"
          md="4"
          style="margin-top: auto"
        >
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
            
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateName"
                label="Fecha"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                disabled
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancelar
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(date)"
              >
                Aceptar
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col style="text-align:end;">
          <v-container>
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" 
                  fab
                  v-bind="attrs"
                  v-on="on"
                  @click="generaReporte"
                  :disabled="disabledButtonPdf"
                >
                  <v-icon>mdi-file</v-icon>
                </v-btn>
              </template>
              <span>Generar Reporte</span>
            </v-tooltip> -->
          </v-container>
        </v-col>
      </v-toolbar>
    </template>
    <div>
      <v-data-table v-show="showTable"
        :headers="headers"
        :items="miembros"
        :loading="loadTable"
        loading-text="Cargando... Por Favor Espera"
        hide-default-footer
        class="elevation-1"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        no-data-text="No hay Cumpleañeros para mostrar."
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-1"
                color="teal"
                dark
                x-small
                fab
                v-bind="attrs"
                v-on="on"
                @click="sendNotification(item)"
                :disabled="item.enviado!=null"
              >
                <v-icon v-if="item.id_tipoContacto == 1">mdi-whatsapp</v-icon>
                <v-icon v-else>mdi-message-text</v-icon>
              </v-btn>
            </template>
            <span>Enviar Felicitación</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <div class="text-center pt-2" v-show="showTable">
      <v-pagination
        v-model="page"
        :length="pageCount"
        circle
      ></v-pagination>
    </div>
    <send-confirm></send-confirm>
  </div>
</template>

<script>
import axios from 'axios'
import sendConfirm from './SendConfirm.vue'

  export default {
    props: {
      idUsuario: {
        //type: Number,
        default: 0
      }
    },

    data: () => ({
      periodo: '',
      catalogos: {
        periodos: [
          {id: 1,
          nom_periodo: "Diario"}
        ]
      },
      date: '',
      dateName: '',
      mes: '',
      menu: '',
      mensaje: '',
      showTable: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        { text: 'Nombre', align: 'start', value: 'nom_miembro' },
        { text: 'Apellidos', align: 'start', value: 'nom_apellidos' },
        { text: 'Cumpleaños', align: 'start', value: 'fec_cumple' },
        { text: 'Edad', align: 'start', value: 'num_edad' },
        { text: 'Congregación', align: 'start', value: 'nom_congregacion' },
        { text: 'Celular', align: 'start', value: 'num_celular'},
        { text: '', value: 'actions', sortable: false, align: 'end' },
      ],
      miembros: [],
      loadTable: false,
      showAlert: false,
      typeAlert: '',
      alertText: '',
      url: process.env.VUE_APP_REPORTES_API
    }),

    created () {
      var self = this

      this.$root.$on("sendConfirmModalClosed",function (Params) {
        if(!Params.success){
            self.viewAlert("error", Params.message)
        }

        self.loadData()
      })

      self.obtenerFecha()
    },

    components:{
      "send-confirm": sendConfirm
    },

    watch: {
      showAlert () {
        var self = this
        if(self.showAlert){
          setTimeout(function(){
            self.showAlert = false;
          }, 3000);
        }
      }
    },

    methods: {
      obtenerFecha (){
        var self = this
        var hoy = new Date()
        
        self.getNombreMes(hoy.getMonth()+1)
    
        self.date = []

        self.date = hoy.getFullYear() + "-" + (hoy.getMonth()+1) + "-" + hoy.getDate()
        self.dateName = hoy.getDate() + " de " + self.mes + " del " + hoy.getFullYear()
        
        self.loadData()
        self.showTable = true
      },

      loadData (){
        var self = this
        var Params = {
          fec_inicio: self.date,
          fec_final: self.date,
          id_congregacion: 0
        }

        self.loadTable = true
        
        axios.post(self.url+'cumpleanios', Params, {
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`
          }
        })
        .then(function (response) {
          self.miembros = response.data.data
          self.loadTable = false
        })
        .catch(function (error) {
          self.viewAlert("error", error);
        });
      },

      getNombreMes (iMes){
        var self = this
       
        switch (iMes) {
          case 1:
            self.mes = "Enero"
            break;
          case 2:
            self.mes = "Febrero"
            break;
          case 3:
            self.mes = "Marzo"
            break;
          case 4:
            self.mes = "Abril"
            break;
          case 5:
            self.mes = "Mayo"
            break;
          case 6:
            self.mes = "Junio"
            break;
          case 7:
            self.mes = "Julio"
            break;
          case 8:
            self.mes = "Agosto"
            break;
          case 9:
            self.mes = "Septiembre"
            break;
          case 10:
            self.mes = "Octubre"
            break;
          case 11:
            self.mes = "Noviembre"
            break;
          case 12:
            self.mes = "Diciembre"
            break;
        }
      },

      sendNotification(miembro){
        var genero

        //console.log(miembro)
        if(miembro.nom_genero == "Mujer"){
          genero = "sierva"
        }else{
          genero = "siervo"
        }
        this.mensaje = 'Gobierno Espiritual *"El Valle de las Bendiciones"* y casa de Dios *"' + miembro.nom_congregacion + '"*,'+
        ' agradece a Dios por la vida de su *'+ genero + ' ' + miembro.nom_miembro + '*, nos gozamos y deseamos un *Feliz Cumpleaños*; y la'+
        ' presencia del Altísimo y buen Padre, en su vida.'+
        '\r\nTus ojos vieron mi cuerpo en formación; todo eso estaba escrito en tu libro. Habías señalado los días de mi vida cuando aún no existía ninguno de ellos.' +
        '\r\nSalmos 139:16'

        if(miembro.id_tipoContacto == 1){
          window.open('https://wa.me/52'+miembro.num_celular+'?text=' + this.mensaje, '_blank');
        }else{
          console.log("Pendiente SMS");
        }

        var Params = {
          show: true,
          id: miembro.id,
          tipo: miembro.id_tipoContacto,
          mensaje: this.mensaje,
          numero: miembro.num_celular
        }
        
        this.$root.$emit("sendConfirmModal", Params)
      },

      viewAlert (type, text){
        this.typeAlert = type
        this.alertText = text
        this.showAlert = true
      }
    }
  }
</script>