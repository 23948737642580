<template>
  <v-dialog v-model="show" persistent max-width="500">
    <v-skeleton-loader
      v-if="loading"
      type="list-item-three-line, list-item-three-line, actions"
      :loading="loading"
    ></v-skeleton-loader>
    <v-card
      v-if="!loading"
      :loading="loading"
      shaped
    >
      <v-card-title>
        <span class="headline">Varones de Casa de Dios {{nom_congregacion}}</span>
      </v-card-title>

      <v-card-text>
        <template>
          <v-toolbar flat color="white">
            <v-col style="text-align:center;">
              <v-text-field
                v-model="searchMiembros"
                append-icon="mdi-account-search"
                label="Buscar Varón"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-toolbar>
        </template>
        <div>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="miembros"
              :single-select="true"
              :search="searchMiembros"
              show-select
              hide-default-footer
              :page.sync="page"
              :items-per-page="itemsPerPage"
              @page-count="pageCount = $event"
              no-data-text="No hay Varones Registrados en está Congregación."
              class="elevation-1"
            >
            </v-data-table>
        </div>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            circle
          ></v-pagination>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
        <v-btn color="blue darken-1" text @click="save">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import axios from 'axios'

export default {
  name: 'ListaVarones',

  data: () => ({
    loading: false,
    miembros: [],
    searchMiembros: '',
    selected: [],
    show: false,
    id_congregacion: 0,
    nom_congregacion: '',
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    headers: [
      { text: 'Nombre', align: 'start', value: 'nom_miembro' },
      { text: 'Apellidos', align: 'start', value: 'nom_apellido'},
      { text: 'Edad', align: 'start', value: 'edad' }
    ],
    urlApi: process.env.VUE_APP_REGISTRO_API
  }),

  created(){
    var self = this

    this.$root.$on("listadoVaronesModal",function (Parameters) {
      self.loading = true
      self.show = Parameters.show
      self.nom_congregacion = Parameters.nom_congregacion
      self.id_congregacion = Parameters.id_congregacion

      self.loadMiembros()
    })
  },

  methods: {
    close () {
      this.show = false
      this.selected = []
      this.searchMiembros = ''
      this.id_congregacion = 0
      this.nom_congregacion = ''
      this.loading = false
      this.miembros = []
    },

    loadMiembros(){
      var self = this

      axios.get(self.urlApi+'varones/listado/'+self.id_congregacion, {
        headers: {
          'Authorization': `Bearer ${localStorage.sessionID}`
        }
      })
      .then(function (response) {
        self.miembros = response.data.data
        self.loading = false
      });
    },

    save () {
      var self = this

      var Params = {
        id_miembro: self.selected[0].id,
        nom_miembro: self.selected[0].nom_miembro,
        nom_apellido: self.selected[0].nom_apellido,
        edad: self.selected[0].edad,
        num_telefono: self.selected[0].num_telefono
      }
      
      self.$root.$emit("listadoVaronesModalClosed", Params)

      self.close()
    }
  }
};
</script>