<template>
  <v-dialog v-model="show" persistent max-width="400">
    <v-skeleton-loader
      v-if="loading"
      type="list-item-three-line, list-item-three-line, actions"
      :loading="loading"
    ></v-skeleton-loader>
    <v-card
      v-if="!loading"
      :loading="loading"
      shaped
    >
      <v-card-title>
        <span class="headline">Registrar</span>
      </v-card-title>

      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-combobox
                v-model ="congregacion"
                :items ="catalogos.congregaciones"
                label="Casa de Dios a la que perteneces"
                item-text="nom_congregacion"
                item-value="id"
                :error-messages="congregacionErrors"
                @input="$v.congregacion.$touch()"
                @blur="$v.congregacion.$touch()"
                :disabled="disabledCongregacion"
              ></v-combobox>
            </v-row>
            <v-row style="margin-top: 5px;">
              <v-text-field
                v-model="nombre"
                label="Nombre(s)"
                :error-messages="nombreErrors"
                @input="$v.nombre.$touch()"
                @blur="$v.nombre.$touch()"
              ></v-text-field>
            </v-row>
            <v-row style="margin-top: 5px;">
              <v-text-field
                v-model="apellido"
                label="Apellido(s)"
                :error-messages="apellidoErrors"
                @input="$v.apellido.$touch()"
                @blur="$v.apellido.$touch()"
              ></v-text-field>
            </v-row>
            <v-row style="margin-top: 5px;">
              <v-col 
                cols="12" 
                md="7" 
                style="padding: 1px;"
              >
                <v-text-field
                  v-model="telefono"
                  label="Teléfono"
                  :error-messages="telefonoErrors"
                  @input="$v.telefono.$touch()"
                  @blur="$v.telefono.$touch()"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12" 
                md="5" 
                style="padding: 1px; padding-left: 50px"
              >
                <v-text-field
                  v-model="edad"
                  label="Edad"
                  :error-messages="edadErrors"
                  @input="$v.edad.$touch()"
                  @blur="$v.edad.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row style="margin-top: 5px;">
              <v-col cols="12" md="7" style="padding: 1px;">
                <v-text-field
                  v-model="telefonoEmergencia"
                  label="Teléfono de Emergencia"
                  :error-messages="telefonoEmergenciaErrors"
                  @input="$v.telefonoEmergencia.$touch()"
                  @blur="$v.telefonoEmergencia.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" style="padding: 1px; padding-left: 50px">
                <v-checkbox
                  v-model="isInvitado"
                  label="Invitado"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row style="margin-top: 5px;">
              <v-checkbox
                v-model="isLider"
                label="Encargado y/o Líder"
              ></v-checkbox>
            </v-row>
            <v-row>
              <v-textarea
                v-model="observaciones"
                auto-grow
                outlined
                rows="3"
                shaped
                no-resize
                label="Observaciones"
                hint="Favor de especificar si tienen alergias, o algún padecimiento."
                persistent-hint
              >
              </v-textarea> 
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
        <v-btn v-if="!isEdit" color="blue darken-1" text @click="save">Registrar</v-btn>
        <v-btn v-else color="blue darken-1" text @click="save">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import axios from 'axios'

import { validationMixin } from 'vuelidate'
import { required, maxLength, numeric, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'PreRegistrar',

  mixins: [validationMixin],

  validations() {    
    return {
      nombre: { required, maxLength: maxLength(250) },
      apellido: { required, maxLength: maxLength(250) },
      edad: { maxLength: maxLength(3), numeric, required },
      congregacion: { required },
      telefono: { required, numeric, maxLength: maxLength(10), minLength: minLength(10)},
      telefonoEmergencia: { required, numeric, maxLength: maxLength(10), minLength: minLength(10)}
    }
  },

  data: () => ({
    loading: false,
    disabledCongregacion: true,
    catalogos: {
      congregaciones: []
    },
    idMiembro: 0,
    nombre: '',
    apellido: '',
    edad: '',
    congregacion: '',
    telefono: '',
    telefonoEmergencia: '',
    isInvitado: false,
    observaciones: '',
    password: '',
    showPassword: false,
    isLider: false,
    show: false,
    showMessage: false,
    message: '',
    isEdit: false,
    flag_registro: false,
    urlApi: process.env.VUE_APP_REGISTRO_API,
    url: process.env.VUE_APP_MIEMBROS_API
  }),

  created(){
    var self = this

    this.$root.$on("preRegistrarModal",function (Parameters) {
      self.show = Parameters.show
      self.catalogos.congregaciones.push(Parameters.congregacion)
      self.congregacion = Parameters.congregacion
      if(Parameters.isReg){
        self.flag_registro = true
      }

      if(Parameters.isEdit){
        self.loadCongregaciones()

        self.loading = true
        self.isEdit = true
        self.idMiembro = Parameters.id
        self.nombre = Parameters.nom_miembro
        self.apellido = Parameters.nom_apellido
        self.telefono = Parameters.num_telefono
        self.edad = Parameters.edad
        self.telefonoEmergencia = Parameters.tel_emergencias
        self.isInvitado = Parameters.flag_invitado
        self.isLider = Parameters.flag_encargado
        self.observaciones = Parameters.observaciones
        //self.disabledCongregacion = false
        self.loading = false
      }else if(self.congregacion.id == 0){
        self.loadCongregaciones()
        self.congregacion = ''
        //self.disabledCongregacion = false
      }
      self.$v.$reset()
    })
  },

  computed: {

    nombreErrors () {
      const errors = []
      if (!this.$v.nombre.$dirty) return errors
      !this.$v.nombre.maxLength && errors.push('Nombre debe tener máximo 250 caracteres')
      !this.$v.nombre.required && errors.push('Nombre es requerido')
      return errors
    },

    apellidoErrors () {
      const errors = []
      if (!this.$v.apellido.$dirty) return errors
      !this.$v.apellido.maxLength && errors.push('El Apellido debe tener máximo 250 caracteres')
      !this.$v.apellido.required && errors.push('El Apellido es requerido')
      return errors
    },

    edadErrors (){
      const errors = []
      if (!this.$v.edad.$dirty) return errors
      !this.$v.edad.maxLength && errors.push('La edad debe tener máximo 3 dígitos')
      !this.$v.edad.numeric && errors.push('La edad sólo admite números')
      !this.$v.edad.required && errors.push('La edad es requerida')
      return errors
    },

    congregacionErrors () {
      const errors = []
      if (!this.$v.congregacion.$dirty) return errors
      !this.$v.congregacion.required && errors.push('La congregación es requerida')
      return errors
    },

    telefonoErrors (){
      const errors = []
      if (!this.$v.telefono.$dirty) return errors
      !this.$v.telefono.maxLength && errors.push('El número de teléfono debe tener 10 dígitos')
      !this.$v.telefono.minLength && errors.push('El número de teléfono debe tener 10 dígitos')
      !this.$v.telefono.numeric && errors.push('El teléfono sólo admite números')
      !this.$v.telefono.required && errors.push('El teléfono es requerido')
      return errors
    },

    telefonoEmergenciaErrors (){
      const errors = []
      if (!this.$v.telefonoEmergencia.$dirty) return errors
      !this.$v.telefonoEmergencia.maxLength && errors.push('El número de teléfono debe tener 10 dígitos')
      !this.$v.telefonoEmergencia.minLength && errors.push('El número de teléfono debe tener 10 dígitos')
      !this.$v.telefonoEmergencia.numeric && errors.push('El teléfono sólo admite números')
      !this.$v.telefonoEmergencia.required && errors.push('El teléfono es requerido')
      return errors
    },

    passwordErrors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.minLength && errors.push('La Contraseña debe tener al menos 6 caracteres.')
      !this.$v.password.required && errors.push('La Contraseña es requerida.')
      return errors
    },
  },

  methods: {
    close () {
      this.show = false
      this.nombre = ''
      this.apellido = ''
      this.edad = ''
      this.telefono = ''
      this.isLider = false
      this.isInvitado = false
      this.observaciones = ''
      this.showMessage = false
      this.message = ''
      this.congregacion = ''
      this.password = ''
      this.showPassword = false
      this.telefonoEmergencia = ''
      this.isEdit = false
      this.idMiembro = 0
      this.disabledCongregacion = true
      this.flag_registro = false
    },

    loadCongregaciones(){
      var self = this

      axios.get(self.urlApi+'congregaciones')
      .then(function (response) {
        self.catalogos.congregaciones = response.data.data
        self.disabledCongregacion = false
      });
    },

    save () {
      var self = this

      self.$v.$touch()

      if(!self.$v.$invalid){
        var Params = {
          id_miembro: self.idMiembro,
          id_congregacion: self.congregacion.id,
          nombre: self.nombre,
          apellido: self.apellido,
          edad: self.edad,
          telefono: self.telefono,
          encargado: self.isLider,
          observaciones: self.observaciones,
          invitado: self.isInvitado,
          telefonoEmergencia: self.telefonoEmergencia,
          flag_registro: self.flag_registro
        }

        axios.post(self.urlApi+'preRegistro', Params, {
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`
          }
        }).then(function (response) {
          self.$root.$emit("preRegistrarModalClosed",response.data)
          self.close()
        })
        .catch(function (error) {
          var Parameters = {
            success: false,
            message: "El Miembro no pudo ser creado, contacte a su Administrador.  Error: ".error
          }
          self.$root.$emit("preRegistrarModalClosed", Parameters)
        });   
      }
    }
  }
};
</script>