var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[[(_vm.showAlert)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":_vm.typeAlert,"transition":"scroll-y-transition","border":"left","elevation":"2"}},[_vm._v(" "+_vm._s(_vm.alertText)+" ")]):_vm._e(),_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":400,"max-width":400,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.showFiltro = true}}},'v-btn',attrs,false),on),[_vm._v(" Filtrar ")])]}}]),model:{value:(_vm.showFiltro),callback:function ($$v) {_vm.showFiltro=$$v},expression:"showFiltro"}},[_c('v-card',{staticClass:"overflow-y-auto"},[_c('v-row',{staticStyle:{"padding-bottom":"20px"},attrs:{"justify":"center"}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v("Filtros")])],1)],1)],1)],1),_c('v-divider'),_c('v-list',[_c('v-list-group',{attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ocupación")])],1)]},proxy:true}])},[_c('v-virtual-scroll',{attrs:{"items":_vm.ocupaciones,"height":"210","item-height":"35"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{key:item.nom_ocupacion,staticStyle:{"padding-left":"10px"}},[_c('v-list-item-action',{staticStyle:{"padding-left":"10px"}},[_c('v-checkbox',{on:{"click":function($event){return _vm.onCheckClick(1, item)}},model:{value:(item.check),callback:function ($$v) {_vm.$set(item, "check", $$v)},expression:"item.check"}})],1),_c('v-list-item-title',[_vm._v(_vm._s(item.nom_ocupacion))])],1)]}}])})],1),_c('v-list-group',{attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Casa de Dios")])],1)]},proxy:true}])},[_c('v-virtual-scroll',{attrs:{"items":_vm.congregaciones,"height":"210","item-height":"35"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{key:item.nom_congregacion,staticStyle:{"padding-left":"10px"}},[_c('v-list-item-action',{staticStyle:{"padding-left":"10px"}},[_c('v-checkbox',{on:{"click":function($event){return _vm.onCheckClick(2, item)}},model:{value:(item.check),callback:function ($$v) {_vm.$set(item, "check", $$v)},expression:"item.check"}})],1),_c('v-list-item-title',[_vm._v(_vm._s(item.nom_congregacion))])],1)]}}])})],1),_c('v-list-group',{attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ministerio")])],1)]},proxy:true}])},[_c('v-virtual-scroll',{attrs:{"items":_vm.ministerios,"height":"210","item-height":"35"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{key:item.nom_ministerio,staticStyle:{"padding-left":"10px"}},[_c('v-list-item-action',{staticStyle:{"padding-left":"10px"}},[_c('v-checkbox',{on:{"click":function($event){return _vm.onCheckClick(3, item)}},model:{value:(item.check),callback:function ($$v) {_vm.$set(item, "check", $$v)},expression:"item.check"}})],1),_c('v-list-item-title',[_vm._v(_vm._s(item.nom_ministerio))])],1)]}}])})],1),_c('v-list-item',[_c('v-card',{staticClass:"overflow-y-auto",attrs:{"outlined":"","height":150,"width":400}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.filtros),function(filtro){return _c('v-col',{key:filtro.nombre},[_c('v-chip',{staticClass:"ma-2",attrs:{"close":"","color":"grey","outlined":""},on:{"click:close":function($event){return _vm.closeChip(filtro)}}},[_vm._v(" "+_vm._s(filtro.nombre)+" ")])],1)}),1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.limpiar()}}},[_vm._v(" Borrar Todo ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.getMiembros()}}},[_vm._v(" Aplicar ")])],1)],1)],1)],1),_c('v-spacer'),_c('v-col',{staticStyle:{"text-align":"center"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-account-search","label":"Buscar Miembros","single-line":"","hide-details":""},model:{value:(_vm.searchMiembros),callback:function ($$v) {_vm.searchMiembros=$$v},expression:"searchMiembros"}})],1),_c('v-col',{staticStyle:{"text-align":"end"}},[_c('v-container',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","small":""},on:{"click":function($event){return _vm.addMiembro()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-multiple-plus")])],1)]}}])},[_c('span',[_vm._v("Agregar Miembro")])])],1)],1)],1)],_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.miembros,"search":_vm.searchMiembros,"hide-default-footer":"","sort-by":"nom_apellidos","loading":_vm.loadTable,"loading-text":"Cargando... Por Favor Espera","page":_vm.page,"items-per-page":_vm.itemsPerPage,"no-data-text":"No hay Miembros para mostrar."},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"teal","dark":"","x-small":"","fab":""},on:{"click":function($event){return _vm.viewMiembro(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"cyan","dark":"","x-small":"","fab":""},on:{"click":function($event){return _vm.editMiembro(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"error","dark":"","x-small":"","fab":""},on:{"click":function($event){return _vm.deleteMiembro(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Dar de Baja")])])]}}])}),_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"circle":"","total-visible":9},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('add-miembro'),_c('remove-miembro')],2)}
var staticRenderFns = []

export { render, staticRenderFns }