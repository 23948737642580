<template>
  <v-dialog v-model="show" persistent max-width="600px">
    <v-skeleton-loader
      v-if="loading"
      type="list-item-avatar, divider, list-item-three-line, list-item-two-line, list-item-three-line, list-item-two-line, actions"
      :loading="loading"
    ></v-skeleton-loader>
    <v-card
      v-if="!loading"
      :loading="loading"
    >
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="nombre"
                  label="Nombre(s)"
                  :disabled="disabled"
                  :error-messages="nombreErrors"
                  :counter="200"
                  @input="$v.nombre.$touch()"
                  @blur="$v.nombre.$touch()"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="apellido"
                  label="Apellidos"
                  :disabled="disabled"
                  :error-messages="apellidoErrors"
                  :counter="200"
                  @input="$v.apellido.$touch()"
                  @blur="$v.apellido.$touch()"
                ></v-text-field>
              </v-col>
            
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="8"
              >
                <v-text-field
                  v-model="correo"
                  label="Correo Electrónico"
                  :disabled="disabled"
                  :counter="200"
                  :error-messages="correoErrors"
                  @input="$v.correo.$touch()"
                  @blur="$v.correo.$touch()"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-combobox
                  v-model ="rol"
                  :items ="catalogos.roles"
                  label="Tipo de Usuario"
                  item-text="nom_rol"
                  item-value="id"
                  :disabled="disabled"
                  :error-messages="rolErrors"
                  @input="$v.rol.$touch()"
                  @blur="$v.rol.$touch()"
                ></v-combobox>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions v-if="isView != 1">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
        <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import axios from 'axios'

import { validationMixin } from 'vuelidate'
import { required, maxLength, email } from 'vuelidate/lib/validators'

export default {
  name: 'AddUsuario',

  mixins: [validationMixin],

  validations() {
    return {
      nombre: { required, maxLength: maxLength(200) },
      apellido: { required, maxLength: maxLength(200) },
      correo: { required, email, maxLength: maxLength(200) },
      rol: { required }
    }
  },

  data: () => ({
    loading: false,
    apellido: '',
    nombre: '',
    correo: '',
    menu: false,
    show: false,
    isView: false,
    isEdit: false,
    isSave: false,
    usuario: {},
    idUsuario: 0,
    rol: '',
    catalogos: {
      roles: []
    },
    urlMiembros: process.env.VUE_APP_MIEMBROS_API,
  }),

  created(){
    var self = this
    
    this.$root.$on("addUsuarioModal",function (Parameters) {
      self.show = Parameters.show
      self.isView = Parameters.isView
      self.isEdit = Parameters.isEdit
      self.usuario = Parameters.usuario
      self.loadCatalogos()
      if(self.isEdit){
        self.loadUsuario()
      }
      self.$v.$reset()
    })
  },

  computed: {
    disabled (){
      return this.isView ? true : false
    },
    
    formTitle () {
      return this.isEdit ? 'Editar Usuario' : this.isView ? 'Usuario' : 'Nuevo Usuario'
    },

    nombreErrors () {
      const errors = []
      if (!this.$v.nombre.$dirty) return errors
      !this.$v.nombre.maxLength && errors.push('Nombre debe tener máximo 200 caracteres')
      !this.$v.nombre.required && errors.push('Nombre es requerido')
      return errors
    },

    apellidoErrors () {
      const errors = []
      if (!this.$v.apellido.$dirty) return errors
      !this.$v.apellido.maxLength && errors.push('El Apellido debe tener máximo 200 caracteres')
      !this.$v.apellido.required && errors.push('Apellido es requerido')
      return errors
    },

    correoErrors (){
      const errors = []
      if (!this.$v.correo.$dirty) return errors
      !this.$v.correo.maxLength && errors.push('El Correo debe tener máximo 200 caracteres')
      !this.$v.correo.email && errors.push('Favor de introducir un correo válido')
      !this.$v.correo.required && errors.push('Correo es requerido')
      return errors
    },

    rolErrors () {
      const errors = []
      if (!this.$v.rol.$dirty) return errors
      !this.$v.rol.required && errors.push('El Tipo de Usuario es requerido')
      return errors
    },
  },

  methods: {
    close () {
      this.show = false
      this.apellido = '',
      this.nombre = '',
      this.correo = '',
      this.rol = '',
      this.isSave = false
    },

    loadCatalogos () {
      this.loadRoles()
    },

    loadRoles () {
      var self = this

      /*axios.get(self.urlMiembros+'roles', {
        headers: {
          'Authorization': `Bearer ${localStorage.sessionID}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(function (response) {
        self.catalogos.roles = response.data.data
      });*/
      self.catalogos.roles = [{id: 2, nom_rol: "Administrador"}]
    },

    loadUsuario () {
      var self = this
      console.log(self.usuario)
      self.idUsuario = self.usuario.id
      self.nombre = self.usuario.nom_usuario
      self.apellido = self.usuario.nom_apellido
      self.correo = self.usuario.des_correo
      self.rol = self.catalogos.roles.find(rol => rol.id === self.usuario.id_rol)
    },

    save () {
      var self = this

      
      self.isSave = true
      self.$v.$touch()

      if(!self.$v.$invalid){
       console.log("Save") 
      }
    },
  }
};
</script>