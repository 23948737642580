var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.disablePreRegistrar)?_c('div',{staticClass:"text--center"},[_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticStyle:{"margin-top":"50px"},attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"blue","indeterminate":""}})],1)],1)],1):_c('div',[[(_vm.showAlert)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":_vm.typeAlert,"transition":"scroll-y-transition","border":"left","elevation":"2"}},[_vm._v(" "+_vm._s(_vm.alertText)+" ")]):_vm._e(),_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-col',{staticStyle:{"margin-top":"auto"}},[_c('v-combobox',{attrs:{"dense":"","outlined":"","items":_vm.catalogos.congregaciones,"label":"Casa de Dios","item-text":"nom_congregacion","item-value":"id"},on:{"change":function($event){return _vm.getMiembrosPreRegistro()}},model:{value:(_vm.congregacion),callback:function ($$v) {_vm.congregacion=$$v},expression:"congregacion"}})],1),_c('div',[_c('v-snackbar',{attrs:{"timeout":5000,"absolute":"","bottom":"","color":"success","text":""},model:{value:(_vm.showTotal),callback:function ($$v) {_vm.showTotal=$$v},expression:"showTotal"}},[_vm._v(" Hay un Total de "),_c('strong',[_vm._v(_vm._s(_vm.totalMiembros))]),_vm._v(" Joven(es) Registrados para está Congregación. ")])],1),_c('v-spacer'),_c('v-col',{staticStyle:{"text-align":"center"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-account-search","label":"Buscar Jóven","single-line":"","hide-details":""},model:{value:(_vm.searchMiembros),callback:function ($$v) {_vm.searchMiembros=$$v},expression:"searchMiembros"}})],1),_c('v-spacer'),_c('v-col',{staticStyle:{"text-align":"end"}},[(_vm.onlyView)?_c('v-container',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","fab":"","small":"","disabled":_vm.disablePreRegistrar},on:{"click":_vm.preRegistrar}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,3203613416)},[_c('span',[_vm._v("Registrar")])])],1):_vm._e()],1)],1)],_c('div',[_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTable),expression:"showTable"}],staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.miembros,"loading":_vm.loadTable,"search":_vm.searchMiembros,"loading-text":"Cargando... Por Favor Espera","hide-default-footer":"","page":_vm.page,"items-per-page":_vm.itemsPerPage,"no-data-text":"No hay Jóvenes Registrados en está Congregación."},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.onlyView)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":"primary","dark":"","x-small":"","fab":""},on:{"click":function($event){return _vm.editMiembro(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar Datos Jóven")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"color":item.flag_cooperacion == 0 ? 'red' : item.flag_cooperacion == 1 ? 'orange' : 'green',"dark":"","x-small":"","fab":""},on:{"click":function($event){return _vm.showCooperacion(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cash-multiple")])],1)]}}],null,true)},[(item.flag_cooperacion == 2)?_c('span',[_vm._v("Cooperación"),_c('br'),_vm._v(" Pagado ")]):(item.flag_cooperacion == 0)?_c('span',[_vm._v(" Cooperación"),_c('br'),_vm._v(" Abonado $0"),_c('br'),_vm._v(" Restan $800 ")]):_c('span',[_vm._v(" Cooperación"),_c('br'),_vm._v(" Abonado $"+_vm._s(item.num_cooperacion)),_c('br'),_vm._v(" Restan $"+_vm._s(800 - item.num_cooperacion)+" ")])]),(_vm.onlyView)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"dark":"","x-small":"","fab":"","color":"primary","disabled":item.flag_cooperacion == 2},on:{"click":function($event){return _vm.addCooperacion(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cash-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Agregar Cooperación")])]):_vm._e()]}}])})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTable),expression:"showTable"}],staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('preRegistrar-miembro'),_c('add-cooperacion'),_c('cooperacion')],2)}
var staticRenderFns = []

export { render, staticRenderFns }