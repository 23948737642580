<template>
  <div>
    <template>
      <v-alert
        v-if="showAlert"
        dense
        outlined
        :type="typeAlert"
        transition="scroll-y-transition"
        border="left"
        elevation="2"
      >
        {{alertText}}
      </v-alert>
      <v-toolbar flat color="white">
        <v-col style="margin-top: auto">
          <v-combobox
            dense
            outlined
            v-model ="periodo"
            :items ="catalogos.periodos"
            label="Periodo"
            item-text="nom_periodo"
            item-value="id"
            @change="changePeriodo()"
          >
          </v-combobox>
        </v-col>
        <v-col style="margin-top: auto">
          <v-combobox
            dense
            outlined
            v-model ="congregacion"
            :items ="catalogos.congregaciones"
            :disabled="disabledCongregacion"
            label="Casa de Dios"
            item-text="nom_congregacion"
            item-value="id"
            @change="loadData()"
          >
          </v-combobox>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          style="margin-top: auto"
        >
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
            
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Seleccione el Rango de Fechas"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :disabled="disabledDate"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              range
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu = false"
              >
                Cancelar
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(date)"
              >
                Aceptar
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col style="text-align:end;">
          <v-container>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" 
                  fab
                  v-bind="attrs"
                  v-on="on"
                  @click="generaReporte"
                  :disabled="disabledButtonPdf"
                >
                  <v-icon>mdi-file-pdf-box</v-icon>
                </v-btn>
              </template>
              <span>Generar Reporte</span>
            </v-tooltip>
          </v-container>
        </v-col>
      </v-toolbar>
    </template>
    <div ref="pdf">
      <v-data-table v-show="showTable"
        :headers="headers"
        :items="miembros"
        :loading="loadTable"
        loading-text="Cargando... Por Favor Espera"
        hide-default-footer
        class="elevation-1"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        no-data-text="No hay Cumpleañeros para mostrar en la fecha seleccionada."
      >
      </v-data-table>
    </div>
    <div class="text-center pt-2" v-show="showTable">
      <v-pagination
        v-model="page"
        :length="pageCount"
        circle
      ></v-pagination>
    </div>
    <img v-show="false" src="../../../storage/img/Membrete.jpg" ref="idImg">
  </div>
</template>

<script>
import axios from 'axios'
import jsPDF from 'jspdf' 
import 'jspdf-autotable'

  export default {
    data: () => ({
      periodo: '',
      catalogos: {
        periodos: [
          {id: 1,
          nom_periodo: "Diario"},
          {id: 2,
          nom_periodo: "Semanal"},
          {id: 3,
          nom_periodo: "Mensual"},
          {id: 4,
          nom_periodo: "Personalizado"},
        ],
        congregaciones: []
      },
      date: [],
      menu: '',
      showTable: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        { text: 'Nombre', align: 'start', value: 'nom_miembro' },
        { text: 'Apellidos', align: 'start', value: 'nom_apellidos' },
        { text: 'Cumpleaños', align: 'start', value: 'fec_cumple' },
        { text: 'Edad', align: 'start', value: 'num_edad' },
        { text: 'Congregación', align: 'start', value: 'nom_congregacion' }
      ],
      miembros: [],
      congregacion: '',
      loadTable: false,
      showAlert: false,
      typeAlert: '',
      alertText: '',
      url: process.env.VUE_APP_REPORTES_API,
      urlCatalogos: process.env.VUE_APP_CATALOGOS_API,
      imagen: ''
    }),

    computed: {
      dateRangeText () {
        this.changePicker()
        return this.date.join(' ~ ')
      },
      disabledDate() {
        if(this.periodo.id == 4){
          return false
        }else{
          return true
        }
      },
      disabledCongregacion() {
        if(this.periodo.id){
          return false
        }else{
          return true
        }
      },
      disabledButtonPdf() {
        if(this.miembros.length > 0){
          return false
        }else{
          return true
        }
      }
    },

    created () {
      var self = this

      self.loadCongregaciones()

      this.$root.$on("generaPdfModal",function () {
        self.date = []
      })
    },

    watch: {
      showAlert () {
        var self = this
        if(self.showAlert){
          setTimeout(function(){
            self.showAlert = false;
          }, 3000);
        }
      }
    },

    methods: {
      changePeriodo (){
        var self = this
        var hoy = new Date()
        
        self.date = []

        if(self.periodo){
          switch(self.periodo.id){
            case 1:
              self.date[0] = hoy.getFullYear() + "-" + (hoy.getMonth()+1) + "-" + hoy.getDate()
              self.date[1] = self.date[0]
              break;
            case 2:
              var diasMes = new Date(hoy.getFullYear(), hoy.getMonth()+1, 0).getDate(),
              diasMesAnt = new Date(hoy.getFullYear(), hoy.getMonth(), 0).getDate(),
              diaInicial = hoy.getDate() - hoy.getDay(), 
              diaFinal = hoy.getDate() + (6 - hoy.getDay()), 
              mesInicial = diaInicial>0 ? hoy.getMonth()+1 : hoy.getMonth() == 0 ? 12 : hoy.getMonth(), 
              mesFinal = diaFinal>diasMes ? hoy.getMonth()+2 == 13 ? 1 : hoy.getMonth()+2 : hoy.getMonth()+1,
              añoInicial = mesInicial==12 && diaInicial<1 ? hoy.getFullYear()-1 : hoy.getFullYear(),
              añoFinal = mesFinal==1 && diaFinal>diasMes ? hoy.getFullYear()+1 : hoy.getFullYear();
              //console.log(diaFinal)
              diaInicial = diaInicial<1 ? diasMesAnt+diaInicial : diaInicial
              diaFinal = diaFinal>diasMes ? diaFinal-diasMes : diaFinal

              self.date[0] = añoInicial + "-" + mesInicial + "-" + diaInicial
              self.date[1] = añoFinal + "-" + mesFinal + "-" + diaFinal
              break;
            case 3:
              var diasMes = new Date(hoy.getFullYear(), hoy.getMonth()+1, 0).getDate()
              
              self.date[0] = hoy.getFullYear() + "-" + (hoy.getMonth()+1) + "-" + 1
              self.date[1] = hoy.getFullYear() + "-" + (hoy.getMonth()+1) + "-" + diasMes
              break;
          }
        }
      },

      changePicker (){
        var self = this

        if(self.date[1]){
          self.loadData()

          self.showTable = true
        }else{
          self.showTable = false
        }
      },

      loadData (){
        var self = this
        var Params = {
          fec_inicio: self.date[0],
          fec_final: self.date[1],
          id_congregacion: self.congregacion.id ? self.congregacion.id : 0
        }

        self.loadTable = true
        
        axios.post(self.url+'cumpleanios', Params, {
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`
          }
        })
        .then(function (response) {
          self.miembros = response.data.data
          self.loadTable = false
        })
        .catch(function (error) {
          self.viewAlert("error", error);
        });
      },

      generaReporte() {
        var self = this
        var columns = [
          {header: "Nombre", dataKey: "nom_miembro"},
          {header: "Apellidos", dataKey: "nom_apellidos"},
          {header: "Cumpleaños", dataKey: "fec_cumple"},
          {header: "Edad", dataKey: "num_edad"},
          {header: "Congregación", dataKey: "nom_congregacion"}
        ];

        var doc = new jsPDF('p', 'pt');
        var pageSize = doc.internal.pageSize;

        self.imagen = self.getBase64Image();

        var repeat = true
        var cont = 0
        var miembrosAux = []
        while (repeat) {
          miembrosAux = self.miembros.slice(cont,cont+15)
          
          doc.addImage(self.imagen, 'JPG', -4, 0, pageSize.width+15, pageSize.height+2) 
        
          doc.setFont("Helvetica")
          doc.setFontSize(32)
          doc.setTextColor('green')
          doc.text('Reporte de Cumpleaños', 120, 180)

          doc.autoTable({
            body: miembrosAux,
            columns: columns,
            startY: 215,
            startX: 50,
            theme: 'striped',
            headStyles: { fillColor: 'green'},
            styles: { halign: 'center'},
            margin: { bottom: 250},
          })

          if(self.miembros.length <= cont+15){
            repeat = false
          }else{
            cont = cont+15
            doc.addPage()
          }
          
        }
        
        var inicio = self.date[0].split('-')
        inicio = inicio [2] + '-' + inicio [1] + '-' + inicio [0]
        var fin = self.date[1].split('-')
        fin = fin [2] + '-' + fin [1] + '-' + fin [0]
        doc.save("Reporte de Cumpleaños del " + inicio + " al " + fin + ".pdf");
      },

      getBase64Image() {
        var img = new Image();
        img = this.$refs.idImg
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/jpg");
        return dataURL;
      },

      loadCongregaciones (){
        var self = this

        axios.get(self.urlCatalogos+'congregaciones', {
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
        .then(function (response) {
          self.catalogos.congregaciones = response.data.data
          self.catalogos.congregaciones.unshift({id: 0, nom_congregacion: "Todas"})
          self.congregacion = {id: 0, nom_congregacion: "Todas"}
        })
        .catch(function (error) {
          alert(error);
        });
      },

      viewAlert (type, text){
        this.typeAlert = type
        this.alertText = text
        this.showAlert = true
      }
    }
  }
</script>