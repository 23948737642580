<template>
  <div class="text--center" v-if="disablePreRegistrar">
    <v-row
      class="fill-height ma-0"
      align="center"
      justify="center"
    >
      <v-col style="margin-top: 50px;"
        align="center"
        justify="center"
      >
        <v-progress-circular
          :size="70"
          :width="7"
          color="blue"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <template>
      <v-alert
        v-if="showAlert"
        dense
        outlined
        :type="typeAlert"
        transition="scroll-y-transition"
        border="left"
        elevation="2"
      >
        {{alertText}}
      </v-alert>
      <v-toolbar flat color="white">
        <v-col style="margin-top: auto">
          <v-combobox
            dense
            outlined
            v-model ="congregacion"
            :items ="catalogos.congregaciones"
            label="Casa de Dios"
            item-text="nom_congregacion"
            item-value="id"
            @change="getMiembrosPreRegistro()"
          >
          </v-combobox>
        </v-col>
         <div>
          <v-snackbar
            :timeout="5000"
            v-model="showTotal"
            absolute
            bottom
            color="success"
            text
          >
            Hay un Total de <strong>{{totalMiembros}}</strong> Joven(es) Registrados para está Congregación.
          </v-snackbar>
        </div>
        <v-spacer></v-spacer>
        <v-col style="text-align:center;">
          <v-text-field
            v-model="searchMiembros"
            append-icon="mdi-account-search"
            label="Buscar Jóven"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col style="text-align:end;">
          <v-container v-if="onlyView">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" 
                  fab 
                  small
                  v-bind="attrs"
                  v-on="on"
                  :disabled="disablePreRegistrar"
                  @click="preRegistrar"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Registrar</span>
            </v-tooltip>
          </v-container>
        </v-col>
      </v-toolbar>
    </template>
    <div>
      <v-data-table v-show="showTable"
        :headers="headers"
        :items="miembros"
        :loading="loadTable"
        :search="searchMiembros"
        loading-text="Cargando... Por Favor Espera"
        hide-default-footer
        class="elevation-1"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        no-data-text="No hay Jóvenes Registrados en está Congregación."
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom v-if="onlyView">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-1"
                color="primary"
                dark
                x-small
                fab
                v-bind="attrs"
                v-on="on"
                @click="editMiembro(item)"
              >
                <v-icon>mdi-account-edit-outline</v-icon>
              </v-btn>
            </template>
            <span>Editar Datos Jóven</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-1"
                :color="item.flag_cooperacion == 0 ? 'red' : item.flag_cooperacion == 1 ? 'orange' : 'green'"
                dark
                x-small
                fab
                v-bind="attrs"
                v-on="on"
                @click="showCooperacion(item)"
              >
                <v-icon>mdi-cash-multiple</v-icon>
              </v-btn>
            </template>
            <!--span>Ver Cooperaciones</span-->
            <span v-if="item.flag_cooperacion == 2">Cooperación<br>
              Pagado
            </span>
            <span v-else-if="item.flag_cooperacion == 0">
              Cooperación<br>
              Abonado $0<br>
              Restan $800
            </span>
            <span v-else>
              Cooperación<br>
              Abonado ${{item.num_cooperacion}}<br>
              Restan ${{ 800 - item.num_cooperacion}}
            </span>
          </v-tooltip>
          <v-tooltip bottom v-if="onlyView">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-1"
                dark
                x-small
                fab
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="addCooperacion(item)"
                :disabled="item.flag_cooperacion == 2"
              >
                <v-icon>mdi-cash-plus</v-icon>
              </v-btn>
            </template>
            <span>Agregar Cooperación</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <div class="text-center pt-2" v-show="showTable">
      <v-pagination
        v-model="page"
        :length="pageCount"
        circle
      ></v-pagination>
    </div>
    <preRegistrar-miembro></preRegistrar-miembro>
    <add-cooperacion></add-cooperacion>
    <cooperacion></cooperacion>
  </div>
</template>

<script>
import axios from 'axios'
import preRegistrarMiembro from './PreRegistrar'
import addCooperacion from './AddCooperacion.vue'
import cooperacion from './Cooperacion.vue'

  export default {
    name: 'PreRegistroJovenes',

    props: {
      tipoUsuario: {
        default: 0
      }
    },

    data: () => ({
      showAlert: false,
      typeAlert: '',
      alertText: '',
      searchMiembros: '',
      miembros: [],
      catalogos: {
        congregaciones: []
      },
      showTable: false,
      loadTable: false,
      showTotal: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        { text: 'Nombre', align: 'start', value: 'nom_miembro' },
        { text: 'Apellidos', align: 'start', value: 'nom_apellido'},
        { text: 'Edad', align: 'start', value: 'edad' },
        { text: 'Casa de Dios', align: 'start', value: 'nom_congregacion' },
        { text: '', value: 'actions', sortable: false, align: 'end' },
      ],
      congregacion: '',
      url: process.env.VUE_APP_REGISTRO_API,
    }),

    components:{
      "preRegistrar-miembro":preRegistrarMiembro,
      "add-cooperacion":addCooperacion,
      "cooperacion":cooperacion
    },

    created(){
      var self = this
      
      self.loadCatalogos()

      self.$root.$on("preRegistrarModalClosed",function (Parameters) {
        if(Parameters.success){
          self.getMiembrosPreRegistro()
        }else{
          self.viewAlert("error", Parameters.message)
        }
      })

      self.$root.$on("addCooperacionModalClosed",function (Parameters) {
        if(Parameters.success){
          self.getMiembrosPreRegistro()
        }else{
          self.viewAlert("error", Parameters.message)
        }
      })
      
    },

    computed: {
      onlyView () {
        if(this.tipoUsuario == 6){
          return false
        }else{
          return true
        }
      },

      disablePreRegistrar() {
        if(this.congregacion){
          return false
        }else{
          return true
        }
      },

      totalMiembros(){
        return this.miembros.length
      }
    },

    watch: {
      showAlert () {
        var self = this
        if(self.showAlert){
          setTimeout(function(){
            self.showAlert = false;
          }, 3000);
        }
      }
    },

    methods: {
      loadCatalogos(){
        this.loadCongregaciones();
      },

      loadCongregaciones(){
        var self = this

        axios.get(self.url+'congregaciones')
        .then(function (response) {
          self.catalogos.congregaciones = response.data.data
          self.catalogos.congregaciones.unshift({id: 0, nom_congregacion: "Todas"})
          self.congregacion = {id: 0, nom_congregacion: "Todas"}
          self.getMiembrosPreRegistro()
        });
      },

      getMiembrosPreRegistro (){
        var self = this
        self.showTable = true
        self.loadTable = true
        
        axios.get(self.url + 'preRegistro/' + self.congregacion.id, {
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
        .then(function (response) {
          self.miembros = response.data.data
          self.loadTable = false
          self.showTotal = true
        })
        .catch(function (error) {
          self.viewAlert('error', error);
        });
      },

      preRegistrar (){
        var self = this

        var Params = {
          show: true,
          congregacion: self.congregacion,
          isEdit: false,
          isReg: false
        }
        
        self.$root.$emit("preRegistrarModal", Params)
      },

      editMiembro(miembro){
        var self = this

        var Params = {
          show: true,
          congregacion: self.congregacion,
          isEdit: true,
          edad: miembro.edad,
          flag_encargado: miembro.flag_encargado,
          flag_invitado: miembro.flag_invitado,
          id: miembro.id,
          nom_miembro: miembro.nom_miembro,
          nom_apellido: miembro.nom_apellido,
          num_telefono: miembro.num_telefono,
          tel_emergencias: miembro.tel_emergencia,
          observaciones: miembro.observaciones
        }
        
        self.$root.$emit("preRegistrarModal", Params)
      },

      addCooperacion (miembro){
        var self = this

        var Params = {
          show: true,
          id: miembro.id,
          idCooperacion: -1
        }
        
        self.$root.$emit("addCooperacionModal", Params)
      },

      showCooperacion (miembro){
        var self = this

        if(self.tipoUsuario != 6){
          var Params = {
            show: true,
            id: miembro.id
          }

          self.$root.$emit("cooperacionModal", Params)
        }
      },

      viewAlert (type, text){
        this.typeAlert = type
        this.alertText = text
        this.showAlert = true
      }
    }
  }
</script>