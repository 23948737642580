<template>
  <v-app v-show="show">
      <v-row no-gutters style="flex-wrap: inherit;">
        <v-col class="flex-grow-0">
          <v-navigation-drawer
            color="teal"
            dark
            expand-on-hover
            permanent
          >
            <template v-slot:prepend>
              <v-list>
                <v-list-item class="px-2">
                  <v-list-item-avatar>
                    <!--v-img src="../assets/logo.png"></v-img-->
                    <v-avatar
                      color="green darken-1"
                      size="56"
                    >{{nomAvatar}}</v-avatar>
                  </v-list-item-avatar>
                </v-list-item>

                <v-list-item link @click="openHome">
                  <v-list-item-content>
                    <v-list-item-title class="title">
                    {{nombre}} {{apellido}}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{correo}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>
            <v-divider></v-divider>
            <v-list
              nav
              dense
            >

              <!-- <v-list-item link @click="openAnuncios" v-if="showAnuncios">
                <v-list-item-icon>
                  <v-icon>mdi-comment-alert-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Anuncios</v-list-item-title>
              </v-list-item> -->

              <v-list-group
                :value="false"
                prepend-icon="mdi-comment-alert-outline"
                no-action
                v-if="showAnuncios"
              >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Anuncios</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item link @click="openAnuncios">
                    <v-list-item-title>Altar</v-list-item-title>
                    <v-list-item-icon>
                      <v-icon></v-icon>
                    </v-list-item-icon>
                  </v-list-item>

                  <v-list-item link @click="openFelicitaciones">
                    <v-list-item-title>Cumpleaños</v-list-item-title>
                    <v-list-item-icon>
                      <v-icon></v-icon>
                    </v-list-item-icon>
                  </v-list-item>
              </v-list-group>

              <v-list-group
                :value="false"
                prepend-icon="mdi-book-open-page-variant"
                no-action
                v-if="showCatalogos"
              >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Catálogos</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item link @click="openMinisterios">
                    <v-list-item-title>Ministerios y/o Comisiones</v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>mdi-account-multiple-outline</v-icon>
                    </v-list-item-icon>
                  </v-list-item>

                  <v-list-item link @click="openOcupaciones">
                    <v-list-item-title>Ocupaciones</v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>mdi-account-multiple-outline</v-icon>
                    </v-list-item-icon>
                  </v-list-item>

                  <v-list-item link @click="openCongregaciones">
                    <v-list-item-title>Congregaciones</v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>mdi-account-multiple-outline</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
              </v-list-group>

              <v-list-group
                :value="false"
                prepend-icon="mdi-human-handsup"
                no-action
                v-if="showCongresoJovenes"
              >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Congreso Jóvenes</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item link @click="openListadosJovenes" v-if="tipoUsuario != 6">
                    <v-list-item-title>Listados</v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>mdi-clipboard-text-multiple-outline</v-icon>
                    </v-list-item-icon>
                  </v-list-item>

                  <v-list-item link @click="openCooperacionJovenes">
                    <v-list-item-title>Pre-Registro</v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>mdi-calendar-plus</v-icon>
                    </v-list-item-icon>
                  </v-list-item>

                  <v-list-item link @click="openRegistroJovenes">
                    <v-list-item-title>Registro</v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>mdi-calendar-check</v-icon>
                    </v-list-item-icon>
                  </v-list-item>

                  <v-list-item link @click="openReportesJovenes" v-if="tipoUsuario != 6">
                    <v-list-item-title>Reportes</v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>mdi-clipboard-list-outline</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  
              </v-list-group>

              <v-list-group
                :value="false"
                prepend-icon="mdi-human-handsup"
                no-action
                v-if="showCongresoVarones"
              >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Congreso Varones</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item link @click="openListadosVarones">
                    <v-list-item-title>Listados</v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>mdi-clipboard-text-multiple-outline</v-icon>
                    </v-list-item-icon>
                  </v-list-item>

                  <v-list-item link @click="openCooperacionVarones">
                    <v-list-item-title>Pre-Registro</v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>mdi-calendar-plus</v-icon>
                    </v-list-item-icon>
                  </v-list-item>

                  <v-list-item link @click="openRegistroVarones">
                    <v-list-item-title>Registro</v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>mdi-calendar-check</v-icon>
                    </v-list-item-icon>
                  </v-list-item>

                  <v-list-item link @click="openReportesVarones">
                    <v-list-item-title>Reportes</v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>mdi-clipboard-list-outline</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  
              </v-list-group>

              <v-list-group
                :value="false"
                prepend-icon="mdi-account-check"
                no-action
                v-if="showUsuarios"
              >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Control Usuarios</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item link @click="openUsuarios">
                    <v-list-item-title>Usuarios</v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>mdi-account-check</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
              </v-list-group>

              <v-list-item link @click="openMiembros" v-if="showMiembros">
                <v-list-item-icon>
                  <v-icon>mdi-account-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Miembros</v-list-item-title>
                <v-list-item-action>
                  {{totalMiembros}}
                </v-list-item-action>
              </v-list-item>

              <v-list-group
                :value="false"
                prepend-icon="mdi-clipboard-outline"
                no-action
                v-if="showReportes"
              >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>Reportes</v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item link @click="openCumpleanios">
                    <v-list-item-title>Cumpleaños</v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>mdi-account-multiple-outline</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-item link @click="openRepMinisterios">
                    <v-list-item-title>Ministerios</v-list-item-title>
                    <v-list-item-icon>
                      <v-icon>mdi-account-multiple-outline</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
              </v-list-group>

            </v-list>
          </v-navigation-drawer>
        </v-col>
        <v-col class="flex-2">
          <v-app-bar
            color="teal"
            dark
            dense
          >
            <v-spacer></v-spacer>

            <v-toolbar-title>{{tittle}}</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn 
              icon
              @click="logout"
            >
              <v-icon
              >mdi-logout</v-icon>
            </v-btn>
          </v-app-bar>
          <v-main>
            <!--v-img v-if="home"
            aspect-ratio="1.32"
              :src='"../../storage/img/2022.jpg"'
            -->
            <v-img v-if="home"
              aspect-ratio="1.32"
              :src='urlImagen+".jpg"'
            >
            </v-img>
            <Miembros v-if="miembros" :totalMiembros.sync="totalMiembros"></Miembros>
            <Ministerios v-if="ministerios"></Ministerios>
            <Ocupaciones v-if="ocupaciones"></Ocupaciones>
            <Congregaciones v-if="congregaciones"></Congregaciones>
            <Usuarios v-if="usuarios" :idUsuario.sync="idUsuario"></Usuarios>
            <GetAnuncios v-if="getAnuncios" :idUsuario="idUsuario"></GetAnuncios>
            <SentAnuncios v-if="sentAnuncios" :idUsuario="idUsuario"></SentAnuncios>
            <Felicitaciones v-if="felicitaciones" :idUsuario="idUsuario"></Felicitaciones>
            <Cumpleanios v-if="cumpleanios"></Cumpleanios>
            <RepMinisterios v-if="repMinisterios"></RepMinisterios>
            <PreRegistroJovenes v-if="preRegistoJovenes" :tipoUsuario="tipoUsuario"></PreRegistroJovenes>
            <ReportesJovenes v-if="reportesJovenes"></ReportesJovenes>
            <ListadosJovenes v-if="listadosJovenes"></ListadosJovenes>
            <RegistroJovenes v-if="registroJovenes" :usuario="idUsuario"></RegistroJovenes>
            <PreRegistroVarones v-if="preRegistroVarones" :tipoUsuario="tipoUsuario"></PreRegistroVarones>
            <ReportesVarones v-if="reportesVarones"></ReportesVarones>
            <ListadosVarones v-if="listadosVarones"></ListadosVarones>
            <RegistroVarones v-if="registroVarones" :usuario="idUsuario"></RegistroVarones>
          </v-main>
        </v-col>
      </v-row>
    <!--/v-toolbar-->
    <!--v-footer app fixed>
      <span>&copy; Nicolás Sainz</span>
    </v-footer-->
  </v-app>
</template>

<script>
import axios from 'axios'
import Miembros from '../components/miembros/Miembros';
import Ministerios from '../components/catalogos/Ministerios';
import Ocupaciones from '../components/catalogos/Ocupaciones';
import Congregaciones from '../components/catalogos/Congregaciones';
import Usuarios from '../components/usuarios/Usuarios';
import GetAnuncios from '../components/anuncios/GetAnuncios';
import SentAnuncios from '../components/anuncios/SentAnuncios';
import Felicitaciones from '../components/anuncios/Felicitaciones';
import Cumpleanios from '../components/reportes/Cumpleaños';
import RepMinisterios from '../components/reportes/Ministerios';
import PreRegistroJovenes from '../components/congresoJovenes/PreRegistro';
import ReportesJovenes from '../components/congresoJovenes/ReportesJovenes.vue';
import ListadosJovenes from '../components/congresoJovenes/Listados.vue';
import RegistroJovenes from '../components/congresoJovenes/Registro.vue';
import PreRegistroVarones from '../components/congresoVarones/PreRegistro';
import ReportesVarones from '../components/congresoVarones/Reportes.vue';
import ListadosVarones from '../components/congresoVarones/Listados.vue';
import RegistroVarones from '../components/congresoVarones/Registro.vue';

export default {
  name: 'Home',

  components: {
    Miembros,
    Ministerios,
    Ocupaciones,
    Congregaciones,
    Usuarios,
    GetAnuncios,
    SentAnuncios,
    Felicitaciones,
    Cumpleanios,
    RepMinisterios,
    PreRegistroJovenes,
    ReportesJovenes,
    ListadosJovenes,
    RegistroJovenes,
    PreRegistroVarones,
    ReportesVarones,
    ListadosVarones,
    RegistroVarones
  },

  computed: {
    nomAvatar (){
      return this.nombre.substring(0,1)+this.apellido.substring(0,1)
    }
  },

  data: () => ({
    miembros: false,
    ministerios: false,
    ocupaciones: false,
    congregaciones: false,
    home: true,
    usuarios: false,
    getAnuncios: false,
    sentAnuncios: false,
    cumpleanios: false,
    repMinisterios: false,
    felicitaciones: false,
    preRegistoJovenes: false,
    reportesJovenes: false,
    listadosJovenes: false,
    registroJovenes: false,
    preRegistroVarones: false,
    reportesVarones: false,
    listadosVarones: false,
    registroVarones: false,
    show: false,
    showMiembros: false,
    showCatalogos: false,
    showReportes: false,
    showUsuarios: false,
    showAnuncios: false,
    showCongresoJovenes: false,
    showCongresoVarones: false,
    tittle: "Bienvenido",
    nombre: '',
    apellido: '',
    correo: '',
    totalMiembros: 0,
    tipoUsuario: 0,
    idUsuario: 0,
    url: process.env.VUE_APP_MIEMBROS_API,
    urlImagen: process.env.VUE_APP_IMAGEN_ANUAL
  }),

  mounted() {
    var self = this
    if(!localStorage.sessionID){
      self.$router.push('/login')
      self.show = false
    }else{
      axios.get(self.url+'auth/user', {
        headers: {
          'Authorization': `Bearer ${localStorage.sessionID}`
        }
      })
        .then(function (response) {
          self.nombre = response.data.data.nombre
          self.apellido = response.data.data.apellido
          self.correo = response.data.data.email
          self.tipoUsuario = response.data.data.id_rol
          self.idUsuario = response.data.data.id
          self.checkPermissions()
          self.show = true
          if(self.tipoUsuario == 3){
            self.openAnuncios()
          }else{
            axios.get(self.url+'totales', {
              headers: {
                'Authorization': `Bearer ${localStorage.sessionID}`
              }
            })
              .then(function (response) {
                self.totalMiembros = response.data.data
              })
              .catch(function (error) {
                self.$router.push('/login')
                self.show = false
            }, 3000);
          }
        })
        .catch(function (error) {
          self.$router.push('/login')
          self.show = false
      }, 3000);
    }
  },

  methods: {
    openMiembros: function () {
      this.home = false
      this.miembros = true
      this.ministerios = false
      this.ocupaciones = false
      this.congregaciones = false
      this.usuarios = false
      this.getAnuncios = false
      this.sentAnuncios = false
      this.felicitaciones = false
      this.cumpleanios = false
      this.repMinisterios = false
      this.preRegistoJovenes = false
      this.reportesJovenes = false
      this.listadosJovenes = false
      this.registroJovenes = false
      this.preRegistroVarones = false
      this.reportesVarones = false
      this.listadosVarones = false
      this.registroVarones = false
      this.tittle = "Miembros"
    },

    openMinisterios: function () {
      this.home = false
      this.miembros = false
      this.ministerios = true
      this.ocupaciones = false
      this.congregaciones = false
      this.usuarios = false
      this.getAnuncios = false
      this.sentAnuncios = false
      this.felicitaciones = false
      this.cumpleanios = false
      this.repMinisterios = false
      this.preRegistoJovenes = false
      this.reportesJovenes = false
      this.listadosJovenes = false
      this.registroJovenes = false
      this.preRegistroVarones = false
      this.reportesVarones = false
      this.listadosVarones = false
      this.registroVarones = false
      this.tittle = "Catálogos"
    },

    openOcupaciones: function () {
      this.home = false
      this.miembros = false
      this.ministerios = false
      this.ocupaciones = true
      this.congregaciones = false
      this.usuarios = false
      this.getAnuncios = false
      this.sentAnuncios = false
      this.felicitaciones = false
      this.cumpleanios = false
      this.repMinisterios = false
      this.preRegistoJovenes = false
      this.reportesJovenes = false
      this.listadosJovenes = false
      this.registroJovenes = false
      this.preRegistroVarones = false
      this.reportesVarones = false
      this.listadosVarones = false
      this.registroVarones = false
      this.tittle = "Catálogos"
    },

    openCongregaciones: function () {
      this.home = false
      this.miembros = false
      this.ministerios = false
      this.ocupaciones = false
      this.congregaciones = true
      this.usuarios = false
      this.getAnuncios = false
      this.sentAnuncios = false
      this.felicitaciones = false
      this.cumpleanios = false
      this.repMinisterios = false
      this.preRegistoJovenes = false
      this.reportesJovenes = false
      this.listadosJovenes = false
      this.registroJovenes = false
      this.preRegistroVarones = false
      this.reportesVarones = false
      this.listadosVarones = false
      this.registroVarones = false
      this.tittle = "Catálogos"
    },

    openHome: function () {
      this.miembros = false
      this.ministerios = false
      this.ocupaciones = false
      this.congregaciones = false
      this.home = true
      this.usuarios = false
      this.getAnuncios = false
      this.sentAnuncios = false
      this.felicitaciones = false
      this.cumpleanios = false
      this.repMinisterios = false
      this.preRegistoJovenes = false
      this.reportesJovenes = false
      this.listadosJovenes = false
      this.registroJovenes = false
      this.preRegistroVarones = false
      this.reportesVarones = false
      this.listadosVarones = false
      this.registroVarones = false
      this.tittle = "Bienvenido"
    },

    openUsuarios: function () {
      this.miembros = false
      this.ministerios = false
      this.ocupaciones = false
      this.congregaciones = false
      this.home = false
      this.usuarios = true
      this.getAnuncios = false
      this.sentAnuncios = false
      this.felicitaciones = false
      this.cumpleanios = false
      this.repMinisterios = false
      this.preRegistoJovenes = false
      this.reportesJovenes = false
      this.listadosJovenes = false
      this.registroJovenes = false
      this.preRegistroVarones = false
      this.reportesVarones = false
      this.listadosVarones = false
      this.registroVarones = false
      this.tittle = "Control de Usuarios"
    },

    openAnuncios: function () {
      this.home = false
      this.miembros = false
      this.ministerios = false
      this.ocupaciones = false
      this.congregaciones = false
      this.usuarios = false
      this.cumpleanios = false
      this.repMinisterios = false
      this.felicitaciones = false
      this.preRegistoJovenes = false
      this.reportesJovenes = false
      this.listadosJovenes = false
      this.registroJovenes = false
      this.preRegistroVarones = false
      this.reportesVarones = false
      this.listadosVarones = false
      this.registroVarones = false
      this.tittle = "Anuncios"
      if(this.tipoUsuario == 3){
        this.getAnuncios = true
      }else{
        this.sentAnuncios = true 
      }
    },

    openCumpleanios: function () {
      this.home = false
      this.miembros = false
      this.ministerios = false
      this.ocupaciones = false
      this.congregaciones = false
      this.usuarios = false
      this.getAnuncios = false
      this.sentAnuncios = false
      this.felicitaciones = false
      this.cumpleanios = true
      this.repMinisterios = false
      this.preRegistoJovenes = false
      this.reportesJovenes = false
      this.listadosJovenes = false
      this.registroJovenes = false
      this.preRegistroVarones = false
      this.reportesVarones = false
      this.listadosVarones = false
      this.registroVarones = false
      this.tittle = "Reporte de Cumpleaños"
    },

    openRepMinisterios: function () {
      this.home = false
      this.miembros = false
      this.ministerios = false
      this.ocupaciones = false
      this.congregaciones = false
      this.usuarios = false
      this.getAnuncios = false
      this.sentAnuncios = false
      this.felicitaciones = false
      this.cumpleanios = false
      this.repMinisterios = true
      this.preRegistoJovenes = false
      this.reportesJovenes = false
      this.listadosJovenes = false
      this.registroJovenes = false
      this.preRegistroVarones = false
      this.reportesVarones = false
      this.listadosVarones = false
      this.registroVarones = false
      this.tittle = "Reporte de Ministerios"
    },

    openFelicitaciones: function () {
      this.home = false
      this.miembros = false
      this.ministerios = false
      this.ocupaciones = false
      this.congregaciones = false
      this.usuarios = false
      this.getAnuncios = false
      this.sentAnuncios = false
      this.felicitaciones = true
      this.cumpleanios = false
      this.repMinisterios = false
      this.preRegistoJovenes = false
      this.reportesJovenes = false
      this.listadosJovenes = false
      this.registroJovenes = false
      this.preRegistroVarones = false
      this.reportesVarones = false
      this.listadosVarones = false
      this.registroVarones = false
      this.tittle = "Envío de Felicitaciones (Cumpleaños)"
    },

    openCooperacionJovenes: function () {
      this.home = false
      this.miembros = false
      this.ministerios = false
      this.ocupaciones = false
      this.congregaciones = false
      this.usuarios = false
      this.getAnuncios = false
      this.sentAnuncios = false
      this.felicitaciones = false
      this.cumpleanios = false
      this.repMinisterios = false
      this.preRegistoJovenes = true
      this.reportesJovenes = false
      this.listadosJovenes = false
      this.registroJovenes = false
      this.preRegistroVarones = false
      this.reportesVarones = false
      this.listadosVarones = false
      this.registroVarones = false
      this.tittle = "Pre-Registro Vástagos del Reino"
    },

    openReportesJovenes: function () {
      this.home = false
      this.miembros = false
      this.ministerios = false
      this.ocupaciones = false
      this.congregaciones = false
      this.usuarios = false
      this.getAnuncios = false
      this.sentAnuncios = false
      this.felicitaciones = false
      this.cumpleanios = false
      this.repMinisterios = false
      this.preRegistoJovenes = false
      this.reportesJovenes = true
      this.listadosJovenes = false
      this.registroJovenes = false
      this.preRegistroVarones = false
      this.reportesVarones = false
      this.listadosVarones = false
      this.registroVarones = false
      this.tittle = "Reportes de Congreso Juvenil"
    },

    openListadosJovenes: function () {
      this.home = false
      this.miembros = false
      this.ministerios = false
      this.ocupaciones = false
      this.congregaciones = false
      this.usuarios = false
      this.getAnuncios = false
      this.sentAnuncios = false
      this.felicitaciones = false
      this.cumpleanios = false
      this.repMinisterios = false
      this.preRegistoJovenes = false
      this.reportesJovenes = false
      this.listadosJovenes = true
      this.registroJovenes = false
      this.preRegistroVarones = false
      this.reportesVarones = false
      this.listadosVarones = false
      this.registroVarones = false
      this.tittle = "Listados de Congreso Juvenil"
    },

    openRegistroJovenes: function () {
      this.home = false
      this.miembros = false
      this.ministerios = false
      this.ocupaciones = false
      this.congregaciones = false
      this.usuarios = false
      this.getAnuncios = false
      this.sentAnuncios = false
      this.felicitaciones = false
      this.cumpleanios = false
      this.repMinisterios = false
      this.preRegistoJovenes = false
      this.reportesJovenes = false
      this.listadosJovenes = false
      this.registroJovenes = true
      this.preRegistroVarones = false
      this.reportesVarones = false
      this.listadosVarones = false
      this.registroVarones = false
      this.tittle = "Registro de Congreso Juvenil"
    },

    openCooperacionVarones: function () {
      this.home = false
      this.miembros = false
      this.ministerios = false
      this.ocupaciones = false
      this.congregaciones = false
      this.usuarios = false
      this.getAnuncios = false
      this.sentAnuncios = false
      this.felicitaciones = false
      this.cumpleanios = false
      this.repMinisterios = false
      this.preRegistoJovenes = false
      this.reportesJovenes = false
      this.listadosJovenes = false
      this.registroJovenes = false
      this.preRegistroVarones = true
      this.reportesVarones = false
      this.listadosVarones = false
      this.registroVarones = false
      this.tittle = "Pre-Registro Centinelas del Reino"
    },

    openReportesVarones: function () {
      this.home = false
      this.miembros = false
      this.ministerios = false
      this.ocupaciones = false
      this.congregaciones = false
      this.usuarios = false
      this.getAnuncios = false
      this.sentAnuncios = false
      this.felicitaciones = false
      this.cumpleanios = false
      this.repMinisterios = false
      this.preRegistoJovenes = false
      this.reportesJovenes = false
      this.listadosJovenes = false
      this.registroJovenes = false
      this.preRegistroVarones = false
      this.reportesVarones = true
      this.listadosVarones = false
      this.registroVarones = false
      this.tittle = "Reportes de Congreso Centinelas del Reino"
    },

    openListadosVarones: function () {
      this.home = false
      this.miembros = false
      this.ministerios = false
      this.ocupaciones = false
      this.congregaciones = false
      this.usuarios = false
      this.getAnuncios = false
      this.sentAnuncios = false
      this.felicitaciones = false
      this.cumpleanios = false
      this.repMinisterios = false
      this.preRegistoJovenes = false
      this.reportesJovenes = false
      this.listadosJovenes = false
      this.registroJovenes = false
      this.preRegistroVarones = false
      this.reportesVarones = false
      this.listadosVarones = true
      this.registroVarones = false
      this.tittle = "Listados de Congreso Centinelas del Reino"
    },

    openRegistroVarones: function () {
      this.home = false
      this.miembros = false
      this.ministerios = false
      this.ocupaciones = false
      this.congregaciones = false
      this.usuarios = false
      this.getAnuncios = false
      this.sentAnuncios = false
      this.felicitaciones = false
      this.cumpleanios = false
      this.repMinisterios = false
      this.preRegistoJovenes = false
      this.reportesJovenes = false
      this.listadosJovenes = false
      this.registroJovenes = false
      this.preRegistroVarones = false
      this.reportesVarones = false
      this.listadosVarones = false
      this.registroVarones = true
      this.tittle = "Registro de Congreso Centinelas del Reino"
    },

    checkPermissions: function () {
      var self = this

      if(self.tipoUsuario == 1){
        self.showMiembros = true
        self.showCatalogos = true
        self.showReportes = true
        //self.showUsuarios = true
        self.showAnuncios = true
        //self.showCongresoJovenes = true
        self.showCongresoVarones = true
      }else if(self.tipoUsuario == 2){
        self.showMiembros = true
        self.showCatalogos = true
        self.showReportes = true
        //self.showAnuncios = true
        //self.showCongresoJovenes = true
        self.showCongresoVarones = true
      }else if(self.tipoUsuario == 3){
        self.showAnuncios = true
      }else if(self.tipoUsuario == 4){
        //self.showRegistro = true
      }else if(self.tipoUsuario == 6){
        //self.showCongresoJovenes = true
      }
    },

    logout: function (){
      var self = this
      axios.get(self.url+'auth/logout', {
        headers: {
          'Authorization': `Bearer ${localStorage.sessionID}`
        }
      })
        .then(function (response) {
          self.$router.push('/login')
          self.tipoUsuario = 0
          self.show = false
        })
        .catch(function (error) {
      }, 3000);
    }

  }
};
</script>
