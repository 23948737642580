<template>
  <v-dialog v-model="show" persistent max-width="400px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }} {{tittleType}}</span>
      </v-card-title>

      <v-card-text>
        <v-text-field
          v-model="name"
          :error-messages="nameErrors"
          :counter="250"
          label="Nombre"
          required
          @keydown.enter="save"
          @input="$v.name.$touch()"
          @blur="$v.name.$touch()"
        ></v-text-field>
        <v-alert
          v-if="showAlert"
          dense
          outlined
          :type="typeAlert"
          transition="scroll-y-transition"
          border="left"
          elevation="2"
        >
          {{alertText}}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
        <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import axios from 'axios'

export default {
  name: 'AddCatalogo',

  mixins: [validationMixin],

  validations: {
    name: { required, maxLength: maxLength(250) },
  },

  data: () => ({
    show: false,
    isEdit: false,
    tittleType: '',
    name:'',
    showAlert: false,
    typeAlert: '',
    alertText: '',
    url: process.env.VUE_APP_CATALOGOS_API
  }),

  created(){
    var self = this

    this.$root.$on("addModalCatalog",function (Parameters) {
      self.show = Parameters.show
      self.isEdit = Parameters.isEdit
      self.tittleType = Parameters.type
      self.name = Parameters.name
      self.id = Parameters.id
      self.$v.$reset()
    })
  },

  computed: {
    formTitle () {
      return this.isEdit ? 'Editar' : this.tittleType == "Ministerio y/o Comisión" ? 'Nuevo' : 'Nueva'
    },
    nameErrors () {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.maxLength && errors.push('Nombre debe tener máximo 25 caracteres')
      !this.$v.name.required && errors.push('Nombre es requerido')
      return errors
    },
  },

  watch: {
    showAlert () {
      var self = this
      if(self.showAlert){
        setTimeout(function(){
          self.showAlert = false;
        }, 3000);
      }
    }
  },

  methods: {

    close () {
      this.show = false
    },

    

    save () {
      var self = this
      var Params = {}
      self.$v.$touch()
      if(!self.$v.$invalid){
        if(self.tittleType == 'Ministerio y/o Comisión'){
          Params = {
            "id" : self.id,
            "nom_ministerio" : self.name
          }

          if(self.isEdit){
            axios.put(self.url+'ministerios', Params, {
              headers: {
                'Authorization': `Bearer ${localStorage.sessionID}`,
                'Access-Control-Allow-Origin': '*'
              }
            })
            .then(function (response) {
              self.$root.$emit("addModalCatalogMinisteriosClosed", response.data)
            })
            .catch(function (error) {
              var Parameters = {
                success: false,
                message: "El Ministerio no pudo ser actualizado, contacte a su Administrador.  Error: ".error
              }

              self.$root.$emit("addModalCatalogMinisteriosClosed", Parameters)
            });
          }else{
            axios.post(self.url+'ministerios', Params, {
              headers: {
                'Authorization': `Bearer ${localStorage.sessionID}`,
                'Access-Control-Allow-Origin': '*'
              }
            })
            .then(function (response) {
              self.$root.$emit("addModalCatalogMinisteriosClosed", response.data)
            })
            .catch(function (error) {
              var Parameters = {
                success: false,
                message: "El Ministerio no pudo ser creado, contacte a su Administrador.  Error: ".error
              }

              self.$root.$emit("addModalCatalogMinisteriosClosed", Parameters)
            });
          }
        }else if(self.tittleType == 'Ocupación'){
          Params = {
            "id" : self.id,
            "nom_ocupacion" : self.name
          }

          if(self.isEdit){
            axios.put(self.url+'ocupaciones', Params, {
              headers: {
                'Authorization': `Bearer ${localStorage.sessionID}`,
                'Access-Control-Allow-Origin': '*'
              }
            })
            .then(function (response) {
              self.$root.$emit("addModalCatalogOcupacionesClosed", response.data)
            })
            .catch(function (error) {
              var Parameters = {
                success: false,
                message: "La Ocupación no pudo ser actualizada, contacte a su Administrador.  Error: ".error
              }

              self.$root.$emit("addModalCatalogOcupacionesClosed", Parameters)
            });
          }else{
            axios.post(self.url+'ocupaciones', Params, {
              headers: {
                'Authorization': `Bearer ${localStorage.sessionID}`,
                'Access-Control-Allow-Origin': '*'
              }
            })
            .then(function (response) {
              self.$root.$emit("addModalCatalogOcupacionesClosed", response.data)
            })
            .catch(function (error) {
              var Parameters = {
                success: false,
                message: "La Ocupación no pudo ser creada, contacte a su Administrador.  Error: ".error
              }

              self.$root.$emit("addModalCatalogOcupacionesClosed", Parameters)
            });
          }
        }else if(self.tittleType == 'Congregación'){
          Params = {
            "id" : self.id,
            "nom_congregacion" : self.name
          }

          if(self.isEdit){
            axios.put(self.url+'congregaciones', Params, {
              headers: {
                'Authorization': `Bearer ${localStorage.sessionID}`,
                'Access-Control-Allow-Origin': '*'
              }
            })
            .then(function (response) {
              self.$root.$emit("addModalCatalogCongregacionesClosed", response.data)
            })
            .catch(function (error) {
              var Parameters = {
                success: false,
                message: "La Congregación no pudo ser actualizada, contacte a su Administrador.  Error: ".error
              }

              self.$root.$emit("addModalCatalogCongregacionesClosed", Parameters)
            });
          }else{
            axios.post(self.url+'congregaciones', Params, {
              headers: {
                'Authorization': `Bearer ${localStorage.sessionID}`,
                'Access-Control-Allow-Origin': '*'
              }
            })
            .then(function (response) {
              self.$root.$emit("addModalCatalogCongregacionesClosed", response.data)
            })
            .catch(function (error) {
              var Parameters = {
                success: false,
                message: "La Congregación no pudo ser creada, contacte a su Administrador.  Error: ".error
              }

              self.$root.$emit("addModalCatalogCongregacionesClosed", Parameters)
            });
          }
        }
        self.close()
      }
    },

    viewAlert (type, text){
      this.typeAlert = type
      this.alertText = text
      this.showAlert = true
    }
  }
};
</script>