<template>
  <v-dialog v-model="show" persistent max-width="280">
    <v-skeleton-loader
      v-if="loading"
      type="list-item-three-line, list-item-three-line, actions"
      :loading="loading"
    ></v-skeleton-loader>
    <v-card
      v-if="!loading"
      :loading="loading"
      shaped
    >
      <v-card-title>
        <span class="headline">Agregar Cooperación</span>
      </v-card-title>

      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-combobox
                v-model ="cooperacion"
                :items ="catalogos.cooperaciones"
                label="Tipo de Cooperación"
                item-text="des_tipoMovimiento"
                item-value="id"
                :error-messages="cooperacionErrors"
                @input="$v.cooperacion.$touch()"
                @blur="$v.cooperacion.$touch()"
              ></v-combobox>
            </v-row>
            <v-row style="margin-top: 5px;">
              <v-text-field
                v-model="cantidad"
                label="Cantidad"
                :error-messages="cantidadErrors"
                @input="$v.cantidad.$touch()"
                @blur="$v.cantidad.$touch()"
              ></v-text-field>
            </v-row>
            <v-row style="margin-top: 5px;">
              <v-datetime-picker
                label="Seleccione la Fecha y Hora"
                v-model="fecha"
                clearText="LIMPIAR"
                dateFormat="dd-MM-yyyy"
                :date-picker-props="dateProps"
                :text-field-props="textFieldProps"
              >
                <template v-slot:timeIcon>
                  <v-icon>mdi-clock-outline</v-icon>
                </template>
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
              </v-datetime-picker>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
        <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import axios from 'axios'

import { validationMixin } from 'vuelidate'
import { required, numeric } from 'vuelidate/lib/validators'

import Vue from 'vue'
import DatetimePicker from 'vuetify-datetime-picker'
import { format } from 'date-fns'

Vue.use(DatetimePicker)

export default {
  name: 'addCooperacion',

  mixins: [validationMixin],

  validations() {
    return {
      cooperacion: { required },
      cantidad: { required, numeric },
      fecha: { required }
    }
  },

  data: () => ({
    catalogos: {
      cooperaciones: []
    },
    cooperacion: '',
    cantidad: '',
    fecha: '',
    show: false,
    idMiembro: 0,
    loading: false,
    isReg: false,
    dateProps: {
      min: "2022-07-01",
      max: "2022-09-31"
    },
    textFieldProps: {
      readonly: true
    },
    urlApi: process.env.VUE_APP_REGISTRO_API,
  }),

  created(){
    var self = this

    this.$root.$on("addCooperacionModal",function (Parameters) {
      self.show = Parameters.show
      self.idMiembro = Parameters.id
      self.loading = true
      self.loadCatalogos()
      if(Parameters.idCooperacion != -1){
        self.isReg = true
        self.fecha = new Date(Date.now())
        if(Parameters.idCooperacion == 0){
          self.cantidad = 800
        }else{
          self.getCooperacion()
        }
      }
  
      self.$v.$reset()
    })
  },

  computed: {

    cooperacionErrors () {
      const errors = []
      if (!this.$v.cooperacion.$dirty) return errors
      !this.$v.cooperacion.required && errors.push('El tipo de Cooperación es requerido')
      return errors
    },

    cantidadErrors () {
      const errors = []
      if (!this.$v.cantidad.$dirty) return errors
      !this.$v.cantidad.numeric && errors.push('La Cantidad sólo admite números')
      !this.$v.cantidad.required && errors.push('La Cantidad es requerida')
      return errors
    },

    fechaErrors (){
      const errors = []
      if (!this.$v.fecha.$dirty) return errors
      !this.$v.fecha.required && errors.push('La fecha es requerida')
      return errors
    }
  },

  methods: {
    close () {
      this.show = false
      this.cooperacion = ''
      this.cantidad = ''
      this.fecha = ''
      this.idMiembro = 0
      this.catalogos.cooperaciones = []

      //this.$root.$emit("addCooperacionModalClosed")
    },

    loadCatalogos() {
      this.loadTiposCooperacion()
    },

    loadTiposCooperacion() {
      var self = this
      axios.get(self.urlApi + 'tiposCooperacion', {
        headers: {
          'Authorization': `Bearer ${localStorage.sessionID}`
        }
      }).then(function (response) {
        if(response.data.success){
          self.catalogos.cooperaciones = response.data.data
          self.cooperacion = self.catalogos.cooperaciones.find(cooperacion => cooperacion.id == 1)
          self.loading = false
        }
      });
    },

    save () {
      var self = this

      self.$v.$touch()

      if(!self.$v.$invalid){
        var Params = {
          id_registro: self.idMiembro,
          tipo_cooperacion: self.cooperacion.id,
          cantidad: self.cantidad,
          fecha_movto: format(self.fecha, 'yyyy-MM-dd HH:mm'),
          isRegistro: self.isReg
        }

        axios.post(self.urlApi+'varones/cooperacion', Params, {
          headers: {
            'Authorization': `Bearer ${localStorage.sessionID}`
          }
        }).then(function (response) {
            self.$root.$emit("addCooperacionModalClosed",response.data)
            self.close()
        })
        .catch(function (error) {
          var Parameters = {
            success: false,
            message: "No se pudo registrar la cooperación, contacte a su Administrador.  Error: ".error
          }
          self.$root.$emit("addCooperacionModalClosed", Parameters)
          self.close()
        });   
      }
    },

    getCooperacion (){
      var self = this
      self.loading = true
      axios.get(self.urlApi + 'varones/totalCooperacion/'+self.idMiembro, {
        headers: {
          'Authorization': `Bearer ${localStorage.sessionID}`
        }
      }).then(function (response) {
        if(response.data.success){
          self.cantidad = 800 - response.data.data
          self.loading = false
        }
      });
    }
  }
};
</script>